import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/jury-votation',
    method: 'get',
    params
  });
}

export function byApplication(params) {
  return request({
    url: '/jury-votation/by-application/'+params['id'],
    method: 'get',
    params
  });
}

export function resultsByApplicationId(id) {
  return request({
    url: '/jury-votation/results-by-application/'+id,
    method: 'get'
  });
}

export function store(data) {
  return request({
    url: '/jury-votation',
    method: 'post',
    data
  });
}

export function show(application_id) {
  return request({
    url: '/jury-votation/'+application_id,
    method: 'get'
  });
}

export function byStatus(params) {
  return request({
    url: '/jury-votation/get-by-status',
    method: 'get',
    params
  });
}

export function unratedApplications(params) {
  return request({
    url: '/jury-votation/unrated-applications',
    method: 'get',
    params
  });
}

export function finals(params) {
  return request({
    url: '/jury-votation/finals',
    method: 'get',
    params
  });
}

export function excelExport(params) {
  return request({
    url: '/jury-votation/export/excel',
    method: 'get',
    params
  });
}