export default function(store) {
  return store.getters['auth/IS_AUTH'] && (
    store.getters['auth/ROLE_NAME'] == 'jury' || 
    store.getters['auth/ROLE_NAME'] == 'jury-ai' || 
    store.getters['auth/ROLE_NAME'] == 'jury-social-sphere' || 
    store.getters['auth/ROLE_NAME'] == 'jury-micro-saas-adtech' || 
    store.getters['auth/ROLE_NAME'] == 'jury-entrepreneurship-fintech' || 
    store.getters['auth/ROLE_NAME'] == 'jury-gamedev' || 
    store.getters['auth/ROLE_NAME'] == 'challenge-judges' || 
    store.getters['auth/ROLE_NAME'] == 'jury-main'
  );
};