export default {
  hello: "hello",
  "Кто может участвовать в конкурсе?":
    "Who can participate in the competition?",
  "В конкурсе могут участвовать только граждане Узбекистана, не достигшие 30 летнего возраста в момент регистрации.":
    "Only citizens of Uzbekistan who are under 30 years of age at the time of registration can participate in the competition.",
  "Почему в конкурсе могут участвовать лица только до 30 лет?":
    "Why can only persons under 30 years old participate in the competition?",
  "Согласно Постановлению Президента № ПП-357 от 23 августа 2022 года, конкурс нацелен на поддержку молодежных стартапов в сфере IT.":
    "According to the Presidential Decree №357 of August 23, 2022 the competition aims to support young IT start-ups.",
  "Как проходить регистрацию гражданам РУз?":
    "How to register citizens of the Republic of Uzbekistan?",
  "Для участия в конкурсе все члены команд с гражданством Республики Узбекистан регистрируются на портале через единую информационную систему идентификации пользователей системы «Электронное правительство» (OneID).":
    "To participate in the competition all team members with citizenship of the Republic of Uzbekistan register on the portal through the unified information system of identification of users of the 'e-Government' system (OneID).",
  "Смогут ли иностранные граждане принять участие на конкурсе?":
    "Can foreign nationals participate in the competition?",
  "В основном направлении конкурса могут принимать участие исключительно граждане Республики Узбекистан, а в специальном направлении - также иностранные граждане, являющиеся сотрудниками резидентов IT Park.":
    "In the main direction of the competition only citizens of the Republic of Uzbekistan can take part, and in the special direction - also foreign citizens who are employees of IT Park residents can participate.",
  "Как проходить регистрацию иностранцам?": "How do foreigners register?",
  "Иностранные граждане и лица без гражданства - команды, участвующие в специальном направлении, регистрируются через форму, доступную на портале.":
    "Foreign citizens and people without citizenship - teams participating in the special direction, register through the form available on the portal.",
  "Может ли одна команда или участник участвовать в различных направлениях?":
    "Can one team or participant participate in different directions?",
  "Одна команда может принять участие только в одном направлении конкурса.":
    "One team can participate in only one direction of the competition.",
  "Как будет проводиться специальное направление?":
    "How will the special entry be conducted?",
  "Состоится презентация проектов по специальному направлению конкурса. Команды-участники специального направления разрабатывают прототип программного продукта в течение 72 часов на особых условиях, представляют и защищают свои проекты в ответственной организации.":
    "Projects will be presented in a special strand of the competition. Teams that take part in this special track will develop a software prototype within 72 hours under special conditions and then present and defend their projects in a responsible organization.",
  "Является ли участие на конкурсе бесплатным?":
    "Is participation in the competition free?",
  "Да, участие является бесплатным.": "Yes, participation is free.",
  "Можно ли командам принимать участие одновременно по двум и более категориям в основном направлении?":
    "Are teams allowed to participate in two or more categories at the same time in the main direction?",
  "Участвовать разрешается только по 1 выбранной категории основного направления, а также в специальном направлении.":
    "Only one chosen category of the main direction and the special direction are allowed to participate.",
  "Как будет проходить онлайн голосование?": "How will online voting work?",
  "Это будет общественное голосование на специальном портале, где голосующие будут авторизоваться через OneID. Голосование пройдет на основе 5 бальной системы: внешний вид проекта; функционал проекта; удобство проекта; готовность (полноценность) проекта и значимость проекта. Исходя из результатов голосования будут определены победители основных направлений.":
    "It will be a public voting on a special portal, where the voters will be authorized through OneID. Voting will be based on a 5-point system: project appearance; project functionality; project usability; project readiness (completeness) and project significance. Based on the voting results, the winners of the main areas will be determined.",
  "Проекты какой стадии могут подать заявку на конкурс?":
    "What stage of the project can apply for the competition?",
  "Так как основной целью конкурса является определение экспорто-ориентированных стартапов и готовых к выходу на международные рынки, мы ожидаем стартапы с уже работающим MVP, у которого уже есть пользователи и продажи. К сожалению, стартапы на этапе идеи не смогут пройти первичный отбор.":
    "As the main goal of the competition is to find export-oriented startups that are ready to enter international markets, we are expecting startups with a working MVP that already has users and sales. Unfortunately, startups at the idea stage will not be able to pass the initial selection.",
  "Технологии на основе искусственного интеллекта":
    "Artificial Intelligence Technologies",
  "Цифровые технологии в социальной сфере":
    "Digital technologies in the social sphere",
  "Технологии в сфере кибербезопасности":
    "Technologies in the field of cybersecurity",
  "Предпринимательство и финансовые технологии":
    "Entrepreneurship and financial technology",
  "Компьютерные и мобильные игры": "Computer and mobile games",
  "Специальное направление - Хакатон": "Special direction - <br> Hackathon",
  "Приветствуем Вас на сайте конкурса President Tech Award! Пожалуйста, оставьте свои контактные данные по форме ниже. Мы отправим Вам уведомление о старте регистрации на конкурс.":
    "Welcome to the President Tech Award website! Please leave your contact details in the form below. We will send you a notification about the start of registration for the competition.",
  "Ваше ФИО:": "Your full name:",
  ФИО: "Full name",
  "Контактный номер:": "Contact number:",
  "Электронная почта:": "E-mail:",
  "Электронная почта": "E-mail",
  Отправить: "Send",
  "Благодарим Вас за проявленный интерес к конкурсу President Tech Award! <b>1 мая 2023</b> года откроется регистрация для всех желающих принять участие в конкурсе лучших стартапов. Желаем удачи!":
    "Thank you for your interest to the President Tech Award! Registration will open on <b> May 1, 2023 </b> for everyone who wants to take part in the competition of the best startups. Good luck!",
  Спасибо: "Thanks!",
  Главная: "Main",
  Направления: "Categories",
  Правила: "Rules",
  Контакты: "Contacts",
  "Конкурс лучших стартапов с общим призовым фондом в":
    "Competition for the best startups with a total prize fund of",
  "Технологии на основе искусственного интеллекта": "AI-driven technologies",
  "Решения для различных отраслей на основе технологий искусственного интеллекта":
    "Solutions for various industries based on artificial intelligence technologies",
  "Проекты, направленные на цифровизацию операционных процессов в образовании, здравоохранении, туризме, транспорте и логистике, электронный перевод государственных услуг или решение существующих проблем в социальных сферах":
    "Projects aimed at digitalization of operational processes in education, healthcare, tourism, transport and logistics, electronic translation of public services or solving existing problems in social spheres",
  "Технологии в сфере кибербезопасности": "Cybersecurity <br> technologies",
  "Проекты, направленные на обеспечение безопасности программных продуктов и программно-аппаратных комплексов в различных сферах, предотвращение киберпреступлений":
    "Projects aimed at ensuring the security of software products and hardware and software complexes in various fields, preventing cybercrime",
  "Предпринимательство и финансовые технологии":
    "Entrepreneurship and financial technologies",
  "Проекты, направленные на создание электронной коммерции, корпоративных решений и финансовых технологий для субъектов хозяйствования и хозяйственных объединений":
    "Projects aimed at creating e-commerce, corporate solutions and financial technologies for business entities and business associations",
  "Компьютерные и мобильные игры": "Computer and mobile games",
  "Проекты, направленные на разработку офлайн- или онлайн-игр для компьютеров и мобильных устройств и геймификацию процессов":
    "Projects aimed at developing offline or online games for computers and mobile devices and gamification of processes",
  "Специальное направление - Хакатон": "Special direction - <br> Hackathon",
  "72 часовой хакатон, где нужно разработать прототипа проекта на основе поставленных задач":
    "72 hour hackathon, where you need to develop a prototype of the project based on the tasks set",
  "Призовой фонд": "Prize fund",
  "Общий призовой фонд 1 000 000 $": "The total prize fund is $ 1,000,000",
  "5 Основных направлений:": "5 Main directions:",
  "1 место": "1st place",
  "2 место": "2nd place",
  "3 место": "3rd place",
  "Специальное направление": "Special direction",
  "Необходимые материалы для заполнения заявки":
    "Necessary materials for filling out the application",
  "Аккаунт в OneID для регистрации": "OneID account for registration",
  "Видео описывающее проект (не более 3 минут)":
    "Video describing the project (no more than 3 minutes)",
  "Презентация проекта (PDF формат) - загрузка файла или ссылки на файл":
    "Project presentation (PDF format) - load a file or links to a file",
  "Ссылка на прототип проекта (MVP)": "Link to the project prototype (MVP)",
  "Ссылка проекта на GitHub": "Project link on GitHub",
  "Условия участия": "Terms of participation",
  "Количество заявок": "Number of applications",
  "Допускается подача заявки на участие в конкурсе только по 1 основному и спец. направлению.":
    "It is allowed to apply for participation in the competition only in 1 main and special direction.",
  "Количество участников в команде": "Number of team members",
  "Для участия в конкурсе состав команд должен состоять из 3-8 человек по основным направлениям и 3-5 человек по специальному направлению.":
    "To participate in the competition, the composition of teams must consist of 3-8 people in the main areas and 3-5 people in a special area.",
  "Стоимость участия": "Cost of participation",
  "Плата за участие в конкурсе не взимается. (Участие в конкурсе – бесплатно)":
    "There is no fee for participation in the competition. (Participation in the competition is free)",
  "Покрытие расходов": "Cost coverage",
  "Расходы, связанные с реализацией проектов, разработанных в рамках конкурса, не возмещаются.":
    "The costs associated with the implementation of projects developed within the framework of the competition are not reimbursed.",
  "Изменения в команде": "Team changes",
  "Изменения в составе команды возможны при условии согласования с организационным комитетом.":
    "Changes in the composition of the team are possible subject to agreement with the organizing committee",
  "Условия отбора участников в состав команды":
    "Conditions for the selection of participants in the team",
  "В основном направлении конкурса могут участвовать граждане Узбекистана, не достигшие 30 лет на день подачи заявки. В специальном направлении могут участвовать иностранные граждане и лица без гражданства, работающие в компаниях резидентах IT Park.":
    "Citizens of Uzbekistan who have not reached the age of 30 on the day of application can participate in the main direction of the competition. Foreign citizens and stateless persons working for IT Park residents can also participate in a special direction.",
  Таймлайн: "Timeline",
  "Предварительный отбор": "Preliminary selection",
  "Онлайн оценивание проектов": "Online project assessment",
  "Онлайн голосование": "Online voting",
  "Прием заявок": "Acceptance of applications",
  Bootcamp: "Bootcamp",
  "Demo Day": "Demo Day",
  Награждение: "Rewarding",
  Галерея: "Gallery",
  "Показать все фотографии": "Show all photos",
  Новости: "News",
  "Спонсоры и партнеры": "Sponsors and partners",
  "Часто задаваемые вопросы": "FAQ",
  "President Tech Award – это конкурс на лучшие цифровые продукты по 5 основным и 1 специальному направлению. Целью которого является привлечь внимание молодежи к сфере IT-технологий и вдохновить их на создание инновационных решений и разработок, способных конкурировать на мировом рынке.":
    "The President Tech Award is a competition for the best digital products in 5 main directions and 1 special direction. The purpose of which is to attract the attention of young people to the field of IT technologies and inspire them to create innovative solutions and developments that can compete in the global market.",
  "за 1 место": "for 1st place",
  "В основном направлении конкурса могут принимать участие граждане Узбекистана, не достигшие 30 лет на день подачи заявки. В специальном направлении могут участвовать и иностранные граждане и лица без гражданства, работающие у резидентов IT-парка.":
    "Citizens of Uzbekistan who have not reached the age of 30 on the day of application can participate in the main direction of the competition. Foreign citizens and stateless persons working for IT Park residents can also participate in a special direction.",
  "20 Марта, 2023": "March 20, 2023",
  "26 Марта, 2023": "March 26, 2023",
  "23 Марта, 2023": "March 23, 2023",
  "Roadshow: Встреча с молодежью в рамках конкурса «mGovAward»":
    "Roadshow: Meeting with young people as part of the mGovAward competition",
  "Во всех областях Узбекистана проводятся семинары mGovAward":
    "mGovAward seminars are held in all regions of Uzbekistan",
  "Roadshow в рамках конкурса «mGovAward» продолжается":
    "Roadshow as part of the mGovAward competition continues",
  "Показать все новости": "Show all news",
  Войти: "Sign in",
  Телефон: "Phone",
  "К вам поступил запрос от fullname на вступление в команду.":
    "You received a request from <b>{fullname}</b> to join the team.",
  "Ваш запрос на вступление в команду team_name отправлен. Ожидайте решение.":
    "Your request to join the {team_name} team has been sent. Wait for the decision.",
  "Вы приняли fullname в свою команду.":
    "You have accepted <b>{fullname}</b> to your the team.",
  "Поздравляем! Вы приняты в команду team_name!":
    "Congratulations! You have been accepted into the <b>{team_name}</b> team!",
  "Вы отказали fullname на вступление в вашу команду.":
    "You refused <b>{fullname}</b> to join your team.",
  "Вам отказали на вступление в команду team_name.":
    "You have been refused entry into the <b>{team_name}</b> team.",
  "Вы удалили fullname из вашей команды.":
    "You have removed <b>{fullname}</b> from your team.",
  "Вас удалили из команды team_name.":
    "You have been removed from the <b>{team_name}</b> team.",
  "fullname покинул вашу команду.": "<b>{fullname}</b> has left your team.",
  "Вы покинули команду team_name.": "You left the <b>{team_name}</b> team.",
  "Ссылка на Livechat President Tech Award":
    "Link to Livechat President Tech Award",
  "Ссылка на телеграм канал конкурса President Tech Award":
    "Link to the telegram channel of the President Tech Award",
  "Ваша заявка еще не сформирована.<br> Обратитесь к капитану команды.":
    "Your application for participation in the competition has not yet been formed. Please contact the captain.",
  "Вы не сможете удалить команду пока в ней находятся другие участники!":
    "You will not be able to delete a team while there are other members in it!",
  Внимание: "Attention",
  "Для граждан Республики Узбекистан":
    "For citizens of the Republic of Uzbekistan",
  "Для иностранных граждан": "For foreign citizens",
  "Нет аккаунта?": "Do not have an account?",
  Зарегистрироваться: "Register",
  "Регистрация граждан Республики Узбекистан проходит через Единая система идентификации (OneID).":
    "Registration of citizens of the Republic of Uzbekistan passes through the Unified Identification System (OneID).",
  Авторизация: "Authorization",
  "публичной оферты": "публичной оферты",
  "Нажимая кнопку “Авторизация”, вы принимаете условия":
    "By clicking the “Authorization” button, you accept the terms",
  "Зарегистрироваться на участие в конкурсе President Tech Award":
    "Register to participate in the President Tech Award competition",
  ПИНФЛ: "Personal identification number of an individual",
  Пол: "Gender",
  "Дата рождения": "Date of birth",
  "Место проживания (Регион)": "Place of residence (Region)",
  "Место проживания (Район)": "Place of residence (District)",
  "Номер телефона": "Phone number ",
  "Роль участника": "The role of the participant",
  "текущее место работы/учебы": "current place of work/study",
  "Код отправлен на ваш номер в смс":
    "The code has been sent to your number in an SMS",
  "Вход для иностранных граждан": "Entrance for foreign citizens",
  Пароль: "Password",
  "Повторите пароль": "Repeat the password",
  "Мой профиль": "My Profile",
  "На рассмотрении": "Under consideration",
  "Дата регистрации": "Date of registration",
  "Дата рождения:": "Date of birth:",
  "Гражданство:": "Citizenship:",
  "Место проживания:": "Place of residence:",
  "Номер телефона:": "Phone number:",
  "Роль участника:": "The role of the participant:",
  "Текущее место работы:": "Current place of work:",
  "Письмо подтверждающее то, что вы работаете в компании резидента IT Park":
    "A letter confirming that you work for a company of IT Park residents",
  "Копия паспорта:": "A copy of the passport:",
  Уведомления: "Notifications",
  Сообщество: "Community",
  "часто задаваемые вопросы": "FAQ",
  выход: "exit",
  "Заявки команд на конкурс": "Applications of teams for the competition",
  "ID команды": "Team ID",
  "Название Команды": "team name",
  "дата регистрации (от)": "date of registration (from)",
  "дата регистрации (до)": "date of registration (until)",
  Регион: "Region ",
  "направление конкурса": "the direction of the competition",
  "статус заявки": "application status",
  "количество участников": "Number of participants:",
  Статус: "status",
  подтвержден: "confirmed",
  "возвращено на доработку": "returned for revision",
  "на рассмотрении": "under consideration",
  "логотип команды": "Team logo:",
  "название команды": "Team name:",
  "регион команды": "Team region:",
  "Роль участника": "The role of the participant",
  "Регистрация иностранных граждан": "Registration of foreign citizens",
  Выберите: "Choose",
  "количество участников": "Number of participants:",
  "Номер телефона:": "Phone number:",
  "электронная почта": "email",
  статус: "status",
  "Список участников": "List of participants",
  ПИНФЛ: "Personal identification number of an individual",
  ФИО: "Full name",
  Пол: "Gender",
  "Гражданство:": "Citizenship:",
  возраст: "age ",
  "место жительства": "place of residence",
  Далее: "Next",
  Закрыть: "Close",
  "Эта электронная почта уже зарегистрирована на платформе":
    "This email is already registered on the platform",
  Внимание: "Attention",
  Выход: "Exit",
  FAQ: "FAQ",
  "E-mail": "E-mail",
  Подтвержден: "Confirmed",
  Мужчина: "Man",
  Женщина: "Woman",
  "Текущее место работы/учебы": "current place of work/study",
  "Текущее место работы": "current place of work",
  "Основная команда": "The main team",
  Хакатон: "Hackathon",
  Название: "Title",
  "Ссылка на восстановление аккаунта отправлено на вашу почту!":
    "A link to recover your account has been sent to your email!",
  "Восстановить аккаунт": "Recover account",
  "Забыли пароль?": "Forgot password?",
  "Описание проекта на русском": "Description of the project in russian",
  "Описание проекта на английском": "Description of the project in english",
  "Ссылка на видеозапись, описывающий проект (до 3 минут)":
    "Link to a video describing the project (up to 3 minutes)",
  "Презентация проекта (файл в формате PDF до 20 Мб)":
    "Project presentation (PDF file up to 20 Mb)",
  "Ссылка на GitHub проекта (Нужно открыть доступ к данному аккаунту - awardsgovuz)":
    "Link to the GitHub project (You need to open access to this account - awardsgovuz)",
  "Ссылка на GitHub проекта": "Link to the GitHub project",
  "Доступы на прототип проекта (MVP)": "Access to the project prototype (MVP)",
  "Например, логин и пароль": "For example, username and password",
  "Логотип проекта в высоком качестве (в формате jpeg, jpg, png до 5 Мб)":
    "Project logo in high quality (in jpeg, jpg, png format up to 5 Mb)",
  "Отправить заявку": "Submit an application",
  "Выберите файл": "Select file",
  "Описание проекта на узбекском": "Description of the project in uzbek",
  "Название проекта": "Project name",
  "Регион команды": "Region of the team",
  "Запросы на вступление": "Requests for entry",
  Участники: "Members",
  "Заявка на конкурс": "Application for the competition",
  "Информация о команде": "Team Information",
  Редактирование: "Editing",
  "Место проживания (Регион)": "Place of residence (Region)",
  "Место проживания (Район)": "Place of residence (District)",
  Сохранить: "Save",
  "Место проживания": "Place of living",
  "Дополнительный номер телефона": "Additional phone number",
  "Моя команда": "My team",
  "Отправлять приглашение": "Send an invitation",
  Действие: "Action",
  Капитан: "Captain",
  Удалить: "Delete",
  Принято: "Accepted",
  "Логотип команды": "Team logo",
  "Название команды": "Team name",
  "История действий": "Action History",
  Исполнитель: "Executor",
  "Дата действия": "Date",
  "Статус действия": "Action status",
  Комментарий: "Comment",
  "Контактный номер": "Contact number",
  "Добавить контактный номер": "Add contact number",
  "Вы уверены, что хотите удалить этого участника?":
    "Are you sure you want to remove this member?",
  Да: "Yes",
  Нет: "No",
  Гражданство: "Citizenship",
  "Копия паспорта (в формате PDF до 15 Мб)":
    "Passport copy (in PDF format up to 15 Mb)",
  "Письмо подтверждающее о том, что вы работаете в компании резидента IT Park":
    "Letter confirming that you work for an IT Park resident company",
  "Код или электронная почта могли быть введены неправильно":
    "Code or email may have been entered incorrectly",
  "Код отправлен на ваш E-mail": "Code sent to your E-mail",
  "Введите код": "Enter code",
  Назад: "Back",
  "Копия паспорта": "Copy of the passport",
  Открыть: "Open",
  Изменить: "Change",
  "Ваш профиль возвращено на доработку.":
    "Your profile has been returned for revision.",
  Причина: "Reason",
  "Поздравляем! Ваш профиль подтвержден.":
    "Congratulations! Your profile has been verified.",
  "Изменить пароль": "Change password",
  "Текущий пароль": "Current Password",
  "Новый пароль": "New password",
  "Повторите новый пароль": "Repeat new password",
  "У вас еще нет команды!": "You don't have a team yet!",
  "Вы можете создать новую команду или присоединиться к команде":
    "You can create a new team or join a team",
  "Создать команду": "Create team",
  "Присоединиться к команде": "Join the team",
  Логотип: "Logo",
  "Введите TEAM ID для вступления в команду": "Enter TEAM ID to join the team",
  "Team ID": "Team ID",
  Поиск: "Search",
  "Ваш результат поиска по TEAM ID: number":
    "Your search result for TEAM ID: number",
  "Отправить запрос": "Send request",
  "Количество участников должно быть от 3 до 5 человек":
    "The number of participants should be from 3 to 5 people",
  "Количество участников должно быть от 3 до 8 человек":
    "The number of participants should be from 3 to 8 people",
  "Ваша заявка на участие в основном направлении оформлена и передана на рассмотрение.":
    "Your application for participation in the main direction has been completed and submitted for consideration.",
  "Ваша заявка на участие в Хакатоне оформлена и передана на рассмотрение.":
    "Your application for participation in the Hackathon has been completed and submitted for consideration.",
  "Поздравляем! Ваша заявка на участие в Хакатоне подтверждена.":
    "Congratulations! Your application for participation in the Hackathon has been confirmed.",
  "Поздравляем! Ваша заявка на участие в основном направлении конкурса подтверждена.":
    "Congratulations! Your application for participation in the main direction of the competition has been confirmed.",
  "Ваша заявка на участие в Хакатоне возвращена на доработку.":
    "Your application for participation in the Hackathon has been returned for revision.",
  "Ваша заявка на участие в основном направлении возвращена на доработку.":
    "Your application for participation in the main direction has been returned for revision.",
  "Показать еще": "Load more",
  "Участники достигшие возраста 30 лет на момент регистрации не могут участвовать в конкурсе.":
    "Participants who have reached the age of 30 at the time of registration cannot participate in the competition.",
  "Запросы на удаление": "Requests for deletion",
  "Капитан команды": "Team captain",
  "Причина удаления": "Reason for deletion",
  Отклонен: "Rejected",
  "Причина отклонения": "Reason for rejection",
  "Дата и время": "Date and time",
  "Ваш запрос на удаление команды отправлен на рассмотрение.":
    "Your request to remove a team has been sent for review.",
  "Ваша команда team_name удалена.": "Your team {team_name} has been deleted.",
  "Ваш запрос на удаление команды отклонен.":
    "Your request to remove a team has been denied.",
  redactor: "Админ по PR",
  registration: "Админ по заявкам",
  admin: "Супер админ",
  Игра: "Game",
  Играть: "Play",
  ПРОДОЛЖИТЬ: "CONTINUE",
  Тест: "Test",
  "Выберите понравившийся проект из категории direction_name":
    "Choose the project you like from this category<br> “{direction_name}”",
  "Телеграм канал": "Telegram channel",
  "Телеграм группа": "Telegram group",
  "Шаблон презентации:": "Presentation Template:",
  файл: "file",
  "Процесс создания и формирования команды для участия в конкурсе завершен 30 июня 2023 года":
    "The process of creating and forming a team for participation in the competition was completed on June 30, 2023",
  "Подача заявки на участие в конкурсе завершена 30 июня 2023 года":
    "The application for participation in the competition closed on June 30, 2023",
  "Вы не можете удалить участника команды. В команде должно быть минимум 3 участника.":
    "You cannot remove a team member. The team must have at least 3 members.",
  "Мои запросы на вступление": "My entry requests",
  "Отправить новый запрос": "Send a new request",
  "Вы можете присоединиться к команде": "You can join a team",
  "Ваш запрос на добавление нового участника в команду отправлен на рассмотрение":
    "Ваш запрос на добавление нового участника в команду отправлен на рассмотрение",
  Принять: "Accept",
  Отказать: "Refuse",
  "История заявок": "Application history",
  "Дата создания": "Date of creation",
  "Тип запроса": "Request type",
  Статус: "Status",
  "Не прошел": "Failed",
  jury: "Jury",
  "Не состоит ни в одной команде": "Not in any team",
  "Процесс подачи заявки для участия в конкурсе завершен 31 августа 2023 года":
    "The application process for participation in the competition ended on August 31, 2023",
  "Оценки жюри": "Jury scores",
  "Ваш проект еще не оценили члены жюри":
    "Your project has not yet been evaluated by the jury members",
  "Ожидайте оценок": "Expect ratings",
  Посмотреть: "Посмотреть",
  "Средний балл": "Average score",
  "В ожидании": "Pending",
  "Не отобраны 2Х": "Failed 2Х",
  "Не отобраны 3Х": "Failed 3Х",
  "Онлайн оценка": "Online assessment",
  "Жюри неотобранные": "Jury unselected",
  "Жюри отобранные": "Jury selected",
  Оценки: "Оценки",
  "1-этап": "stage 1",
  "2-этап": "stage 2",
  Сертификаты: "Certificates",
  "Сертификат участника Хакатон": "Hackathon Certificate of Participation",
  Скачать: "Download",
  Финал: "Final",
  "Результаты жюри": "Jury results",
  Направление: "Direction",
  "Количество заявок": "Number of applications",
  "Направление конкурса": "Direction of the competition",
  "Средняя оценка": "Average score",
  "Средний балл (1 этап)": "Average score (stage 1)",
  "Средний балл (2 этап)": "Average score (stage 2)",
  "макс. 50 баллов": "max. 50 points",
  "Перейти на онлайн голосование": "Switch to online voting",
  Голосовать: "Vote",
  дней: "days",
  часов: "hours",
  минут: "minutes",
  секунд: "seconds",
  "Стартовал этап онлайн-голосования конкурса President Tech Award с общим призовым фондом $1 млн!":
    "The online voting stage of the President Tech Award competition with a total prize fund of <span>$1 million</span> has commenced!",
  "Каждый голос очень важен! Присоединяйтесь к общественному голосованию, зарегистрировавшись через систему MyID. Поддержите проекты и помогите определить победителей!":
    "<span>Each vote is very important!</span> Join the public voting by registering through the MyID system. Support the projects and contribute to determining the winners!",
  "25 лучших команд, отобранных по основным направлениям конкурса ждут, чтобы вы оценили их проекты по следующим критериям:":
    "The top 25 teams, selected across the main competition categories, await your evaluation of their projects based on the following criteria:",
  "Внешний вид (интерфейс и дизайн)": "Appearance (interface and design)",
  Удобство: "Convenience",
  Завершенность: "Completeness",
  Актуальность: "Relevance",
  Функциональность: "Functionality",
  "Результаты онлайн голосования": "Online voting results",
  Команда: "Team",
  Балл: "Grade",
  Голоса: "Votes",
  Голосование: "Voting",
  "Выберите категорию проекта": "Choose project category",
  "Презентация проекта": "Presentation of project",
  "Доступы к системе": "System access",
  "Логотип проекта": "Project logo",
  "Оцените выбранный проект": "Rate the selected project",
  "Проставьте каждому из пунктов свою оценку. Исходите из вашего личного опыта.":
    "Please rate each item. Based on your personal experience.",
  Дизайн: "Design",
  Функционал: "Functional",
  Готовность: "Readiness",
  "Дата оценки": "Date of assessment",
  "По критериям": "According to criteria",
  "Посмотреть файл": "View file",
  "Регистрация на голосование": "Registration to vote",
  "Пройдите регистрацию и оставьте свой голос за интересный для вас проект":
    "Register and leave your vote for a project that interests you",
  "Вы уже проголосовали": "You have been voted",
  "Ссылка на видеозапись, описывающий проект":
    "Link to video describing the project",
  Проект: "Project",
  "Как будут определены победители основного направления конкурса?":
    "How will the winners of the main direction of the competition be determined?",
  "Победители будут определены на этапе онлайн голосования, голосование пройдет на основе 5 бальной системы: внешний вид проекта; функционал проекта; удобство проекта; готовность (завершенность) проекта и значимость проекта. Победителями по итогам голосования будут являться проекты набравшие максимальный балл.":
    "The winners will be determined at the online voting stage. The voting will be based on a 5-point system: project appearance; project functionality; project usability; project readiness (completeness) and project significance. The winners of the voting will be the projects with the highest score.",
  "Видеоинструкция по голосованию": "Video instructions for voting",
  Подробнее: "More",
  LastWinners: "Previous season winners:",
  "Ознакомление с финалистами": "Meet the finalists",
  "Ovoz berish vaqti tugadi": "Voting time is over",
  Настройки: "Settings",
  Редактировать: "Edit",
  "Место прописки": "Place of residence",
  Добавить: "Add",
  "Технологии в сфере информационной и кибербезопасности":
    "Cybersecurity technologies",
  "Проекты, направленные на разработку офлайн — или онлайн-игр для компьютеров и мобильных устройств и геймификацию процессов":
    "Проекты, направленные на разработку офлайн — или онлайн-игр для компьютеров и мобильных устройств и геймификацию процессов",
  Регистрация: "Registration",
  "President Tech Award —":
    "President Tech Award is a competition for the best software products across 5 main and 1 special direction. The aim of the competition is to attract the attention of youth to the IT technologies sphere and inspire them to create innovative solutions and developments capable of competing on the global market.",
  "Micro-SaaS — это подход к разработке ПО, который использует небольшие, независимые сервисы для создания приложений. Основные преимущества включают гибкость, масштабируемость и упрощение обслуживания.<br/> AdTech — это технологии для управления и оптимизации цифровой рекламы. Включает инструменты для таргетинга, ретаргетинга и автоматизации рекламных кампаний, с целью повышения эффективности и ROI.":
    "Micro-SaaS is a software development approach that uses small, independent services to build applications. The main advantages include flexibility, scalability, and simplified maintenance.<br/> AdTech refers to technologies for managing and optimizing digital advertising. It includes tools for targeting, retargeting, and automating advertising campaigns to increase efficiency and ROI.",
  "Специальное направление: Хакатон": "Special direction: Hackathon",
  "Технологические решения в сфере Micro-SaaS и AdTech":
    "Technological solutions in the field of Micro-SaaS and AdTech",
  "Tahrirchi — проект":
    "Tahrirchi — project designed to facilitate writing in Uzbek. The program checks spelling, contextual, stylistic, and grammatical errors in Uzbek texts.",
  "Docum.ai — проект":
    "Docum.ai — project that fully automates customer service through artificial intelligence.",
  "Alama — платформа":
    "Alama — online course platform in local languages. The program employs artificial intelligence to translate text and speech from the world's best online courses into local languages.",
  "Mentalaba — проект":
    "Mentalaba — project designed to assist students in discovering, researching, and comparing higher education institutions to determine the most suitable academic institution for them.",
  "Lalu— мобильная платформа":
    "Lalu — mobile platform for young and expectant mothers, where users can find reliable information and monitor their health during pregnancy and childcare.",
  "Uztrip— помощь":
    "Uztrip — will help to discover the best places to visit, hotels, restaurants, and attractions.",
  "ALATOR — проект":
    "ALATOR — project that equips the ability to detect and prevent vulnerabilities, cyber threats, cyber-attacks, and other malicious activities identified in information systems and resources produced by the private and public sectors.",
  "PCP — проект":
    "PCP — project committed to safeguarding digital assets and ensuring the secure utilization of the Internet.",
  "SHIFO24— инновационный проект":
    "SHIFO24 — innovative project aimed at ensuring security in the field of online medical services.",
  "Yemak — служба доставки еды.": "Yemak — food delivery service.",
  "Joyla— торговая площадка":
    "Joyla — online marketplace that enables the swift buying and selling of various products.",
  "IMAN — финтех-платформа":
    "IMAN — fintech platform supporting Islamic banking infrastructure for banks.",
  "AirPilot — мобильная игра":
    "AirPilot — mobile game crafted to spark interest in aerodynamics and aerospace technologies among youth, offering the opportunity to control and get acquainted with diverse means of managing air traffic in both virtual and real conditions.",
  "The Rise of Jaloliddin":
    "The Rise of Jaloliddin — historical game aiming to depict the history of the Turkestan people.",
  "The Golden Horde":
    "The Golden Horde: Turan — medieval first-person single-player action RPG rooted in realism and historical events in Central Asia.",
    "Оставьте заявку и мы оповестим вас о начале принятия заявок.":"Leave an application and we will notify you when the application acceptance begins.",
    "Отлично. Мы отправим вам сообщение, когда откроется регистрация конкурса.":"Great. We will send you a message when the contest registration opens.",
    "Процесс создания и формирования команды для участия в конкурсе завершен 25 августа 2024 года":"The process of creating and forming a team for participation in the competition was completed on August 25, 2024",
    "Процесс подачи заявки для участия в конкурсе завершен 25 августа 2024 года":"The application process for participation in the competition ended on August 25, 2024",

    "Процесс подачи заявки для участия в конкурсе завершен 30 августа 2024 года":"The application process for participation in the competition ended on August 30, 2024",
    "Процесс создания и формирования команды для участия в Хакатоне завершен 30 августа 2024 года.":"The process of creating and forming a team to participate in the Hackathon was completed on August 30, 2024.",
    "Презентация прикреплена":"Presentation attached",
    "Оценка поставлена команде": "The score was given to the team",
    "Чекпоинты Хакатона | Чекпоинт 1":"Hackathon checkpoints | Checkpoint 1",
    "Чекпоинты Хакатона | Чекпоинт 2":"Hackathon checkpoints | Checkpoint 2",
    "Чекпоинты Хакатона | Чекпоинт 3":"Hackathon checkpoints | Checkpoint 3",
    "Чекпоинты Хакатона":"Hackathon checkpoints",
    "Чекпоинт 1":"Checkpoint 1",
    "Чекпоинт 2":"Checkpoint 2",
    "Чекпоинт 3":"Checkpoint 3",
    "Описание":"Description",
    "Оценочный лист":"Score sheet",
    "Найменование критерия": "Name of criterion",
    "Оценки":"Ratings",
    "ВСЕГО":"TOTAL",
    "Подтвердить":"Confirm",
    "Баллы успешно отправлены!":"Points sent successfully!",
    "Ссылка на Github добавлена":"Github link added",
    "Программа":"Agenda",
  };
