const routes = [
  {
    path: 'applications',
    name: 'AdminApplications',
    meta: { middleware: 'AuthAdmin|AuthRegistration', title: 'Заявки команд на конкурс' },
    component: () =>
    import("@/views/admin/Applications/Index"),
  },{
    path: 'applications/:id/show',
    name: 'AdminApplicationsShow',
    meta: { middleware: 'AuthAdmin|AuthRegistration', title: 'Заявки команд на конкурс' },
    component: () =>
    import("@/views/admin/Applications/Show"),
  },{
    path: 'foreign-members',
    name: 'AdminForeignMembers',
    meta: { middleware: 'AuthAdmin|AuthRegistration', title: 'Регистрация иностранных граждан' },
    component: () =>
    import("@/views/admin/Members/Foreigns"),
  },{
    path: 'foreign-members/:id/show',
    name: 'AdminForeignMembersShow',
    meta: { middleware: 'AuthAdmin|AuthRegistration', title: 'Регистрация иностранных граждан' },
    component: () =>
    import("@/views/admin/Members/Show"),
  },{
    path: 'members',
    name: 'AdminMembers',
    meta: { middleware: 'AuthAdmin|AuthRegistration', title: 'Список участников' },
    component: () =>
    import("@/views/admin/Members/Index"),
  },{
    path: 'members/:id/show',
    name: 'AdminMembersShow',
    meta: { middleware: 'AuthAdmin|AuthRegistration', title: 'Список участников' },
    component: () =>
    import("@/views/admin/Members/Show"),
  },
  
  
  {
    path: 'news',
    name: 'AdminNews',
    meta: { middleware: 'AuthAdmin|AuthRedactor', title: 'Новости' },
    component: () =>
    import("@/views/admin/News/Index"),
  },{
    path: 'news/create',
    name: 'AdminNewsCreate',
    meta: { middleware: 'AuthAdmin|AuthRedactor', title: 'Новости' },
    component: () =>
    import("@/views/admin/News/Form"),
  },{
    path: 'news/:id/show',
    name: 'AdminNewsShow',
    meta: { middleware: 'AuthAdmin|AuthRedactor', title: 'Новости' },
    component: () =>
    import("@/views/admin/News/Show"),
  },{
    path: 'news/:id/edit',
    name: 'AdminNewsEdit',
    meta: { middleware: 'AuthAdmin|AuthRedactor', title: 'Новости' },
    component: () =>
    import("@/views/admin/News/Form"),
  },


  {
    path: 'gallery',
    name: 'AdminGallery',
    meta: { middleware: 'AuthAdmin|AuthRedactor', title: 'Галерея' },
    component: () =>
    import("@/views/admin/Gallery/Index"),
  },{
    path: 'gallery-videos',
    name: 'AdminGalleryVideos',
    meta: { middleware: 'AuthAdmin|AuthRedactor', title: 'Галерея' },
    component: () =>
    import("@/views/admin/Gallery/Videos"),
  },


  {
    path: 'users',
    name: 'AdminUsers',
    meta: { middleware: 'AuthAdmin', title: 'Пользователи' },
    component: () =>
    import("@/views/admin/Users/Index"),
  },


  {
    path: 'settings',
    name: 'AdminSettings',
    meta: { middleware: 'AuthAdmin|AuthRegistration|AuthRedactor|AuthJury||AuthJuryHackathon', title: 'Настройки' },
    component: () =>
    import("@/views/Settings"),
  },


  {
    path: 'team-removal-requests',
    name: 'AdminTeamRemovalRequests',
    meta: { middleware: 'AuthAdmin|AuthRegistration', title: 'Запросы на удаление команды' },
    component: () =>
    import("@/views/admin/TeamRemovalRequests/Index"),
  },{
    path: 'team-removal-requests/:id/show',
    name: 'AdminTeamRemovalRequestsShow',
    meta: { middleware: 'AuthAdmin|AuthRegistration', title: 'Запросы на удаление команды' },
    component: () =>
    import("@/views/admin/TeamRemovalRequests/Show"),
  },

  {
    path: 'team-change-requests',
    name: 'AdminTeamChangeRequests',
    meta: { middleware: 'AuthAdmin|AuthRegistration', title: 'Запросы на изменения в команду' },
    component: () =>
    import("@/views/admin/TeamChangeRequests/Index"),
  },{
    path: 'team-change-requests/:id/show',
    name: 'AdminTeamChangeRequestsShow',
    meta: { middleware: 'AuthAdmin|AuthRegistration', title: 'Запросы на изменения в команду' },
    component: () =>
    import("@/views/admin/TeamChangeRequests/Show"),
  },

  {
    path: 'teams',
    name: 'AdminTeams',
    meta: { middleware: 'AuthAdmin|AuthRegistration', title: 'Команды' },
    component: () =>
    import("@/views/admin/Teams/Index"),
  },{
    path: 'teams/:id/show',
    name: 'AdminTeamsShow',
    meta: { middleware: 'AuthAdmin|AuthRegistration', title: 'Команды' },
    component: () =>
    import("@/views/admin/Teams/Show"),
  },
  
  
  {
    path: 'votations',
    name: 'AdminVotations',
    meta: { middleware: 'AuthAdmin', title: 'Результаты онлайн голосования' },
    component: () =>
    import("@/views/admin/Votations/Index"),
  },{
    path: 'votations/:id/application',
    name: 'AdminVotationShowApplication',
    meta: { middleware: 'AuthAdmin', title: 'Результаты онлайн голосования' },
    component: () =>
    import("@/views/admin/Votations/ShowApplication"),
  },{
    path: 'votations/:id/score',
    name: 'AdminVotationShowScore',
    meta: { middleware: 'AuthAdmin', title: 'Результаты онлайн голосования' },
    component: () =>
    import("@/views/admin/Votations/ShowScore"),
  },


  {
    path: 'dashboard',
    name: 'AdminDashboard',
    meta: { middleware: 'AuthAdmin', title: 'Dashboard' },
    component: () =>
    import("@/views/admin/Dashboard/Index"),
  },

  {
    path: 'checklist',
    name: 'AdminChecklist',
    meta: { middleware: 'AuthAdmin|AuthHackathon', title: 'Чек лист участников Хакатон' },
    component: () =>
    import("@/views/admin/Checklist/Index"),
  },

  {
    path: 'jury-results',
    name: 'AdminJuryResults',
    meta: { middleware: 'AuthAdmin', title: 'Результаты жюри' },
    component: () =>
    import("@/views/admin/JuryResults/Index"),
  },{
    path: 'jury-results/:id/application',
    name: 'AdminJuryResultApplication',
    meta: { middleware: 'AuthAdmin', title: 'Результаты жюри' },
    component: () =>
    import("@/views/admin/JuryResults/ShowApplication"),
  },{
    path: 'jury-results/:id/value',
    name: 'AdminJuryResultValue',
    meta: { middleware: 'AuthAdmin', title: 'Результаты жюри' },
    component: () =>
    import("@/views/admin/JuryResults/ShowScore"),
  },{
    path: 'jury-results/:id/value2',
    name: 'AdminJuryResultValue2',
    meta: { middleware: 'AuthAdmin', title: 'Результаты жюри' },
    component: () =>
    import("@/views/admin/JuryResults/ShowScore"),
  },

  {
    path: 'direction-jury-results',
    name: 'AdminDirectionJuryResults',
    meta: { middleware: 'AuthAdmin', title: 'Demo Day - 1 stage' },
    component: () =>
    import("@/views/admin/JuryResults/Index"),
  },{
    path: 'direction-jury-results/:id/application',
    name: 'AdminDirectionJuryResultApplication',
    meta: { middleware: 'AuthAdmin', title: 'Demo Day - 1 stage' },
    component: () =>
    import("@/views/admin/JuryResults/ShowApplication"),
  },{
    path: 'direction-jury-results/:id/value',
    name: 'AdminDirectionJuryResultValue',
    meta: { middleware: 'AuthAdmin', title: 'Demo Day - 1 stage' },
    component: () =>
    import("@/views/admin/JuryResults/ShowScore"),
  },

  {
    path: 'demo-day-final-results',
    name: 'AdminDemoDayFinalResults',
    meta: { middleware: 'AuthAdmin', title: 'Demo Day Final' },
    component: () =>
    import("@/views/admin/JuryResults/Index"),
  },{
    path: 'demo-day-final-results/:id/application',
    name: 'AdminDemoDayFinalResultApplication',
    meta: { middleware: 'AuthAdmin', title: 'Demo Day Final' },
    component: () =>
    import("@/views/admin/JuryResults/ShowApplication"),
  },{
    path: 'demo-day-final-results/:id/value',
    name: 'AdminDemoDayFinalResultValue',
    meta: { middleware: 'AuthAdmin', title: 'Demo Day Final' },
    component: () =>
    import("@/views/admin/JuryResults/ShowScore"),
  },

  {
    path: 'fact-checking-results',
    name: 'AdminFactCheckingResults',
    meta: { middleware: 'AuthAdmin', title: 'Fact Checking' },
    component: () =>
    import("@/views/admin/FactChecking/Index"),
  },{
    path: 'fact-checking-results/:id/application',
    name: 'AdminFactCheckingResultApplication',
    meta: { middleware: 'AuthAdmin', title: 'Fact Checking' },
    component: () =>
    import("@/views/admin/FactChecking/ShowApplication"),
  },{
    path: 'fact-checking-results/:id/value',
    name: 'AdminFactCheckingResultValue',
    meta: { middleware: 'AuthAdmin', title: 'Fact Checking' },
    component: () =>
    import("@/views/admin/FactChecking/ShowScore"),
  },
  
  {
    path: 'jury-applications',
    name: 'AdminJuryApplications',
    meta: { middleware: 'AuthJury|AuthJuryHackathon', title: 'Заявки на конкурс' },
    component: () =>
    import("@/views/admin/JuryApplications/Index"),
  },{
    path: 'jury-applications/show/:id/application',
    name: 'AdminJuryApplicationsShowApplication',
    meta: { middleware: 'AuthJury|AuthJuryHackathon', title: 'Заявки на конкурс' },
    component: () =>
    import("@/views/admin/JuryApplications/ShowApplication"),
  },{
    path: 'jury-applications/show/:id/assessment',
    name: 'AdminJuryApplicationsShowAssessment',
    meta: { middleware: 'AuthJury|AuthJuryHackathon', title: 'Заявки на конкурс' },
    component: () =>
    import("@/views/admin/JuryApplications/ShowAssessment"),
  },{
    path: 'jury-applications-drafts',
    name: 'AdminJuryApplicationsDrafts',
    meta: { middleware: 'AuthJury|AuthJuryHackathon', title: 'Заявки на конкурс' },
    component: () =>
    import("@/views/admin/JuryApplications/Index"),
  },{
    path: 'jury-applications-graded',
    name: 'AdminJuryApplicationsGraded',
    meta: { middleware: 'AuthJury|AuthJuryHackathon', title: 'Заявки на конкурс' },
    component: () =>
    import("@/views/admin/JuryApplications/Index"),
  },

  {
    path: 'fact-checking-applications',
    name: 'AdminFactCheckingApplications',
    meta: { middleware: 'AuthTechnicalExpert', title: 'Fact Checking' },
    component: () =>
    import("@/views/admin/FactCheckingApplications/Index"),
  },{
    path: 'fact-checking-applications/show/:id/application',
    name: 'AdminFactCheckingShowApplication',
    meta: { middleware: 'AuthTechnicalExpert', title: 'Fact Checking' },
    component: () =>
    import("@/views/admin/FactCheckingApplications/ShowApplication"),
  },{
    path: 'fact-checking-applications/show/:id/assessment',
    name: 'AdminFactCheckingShowAssessment',
    meta: { middleware: 'AuthTechnicalExpert', title: 'Fact Checking' },
    component: () =>
    import("@/views/admin/FactCheckingApplications/ShowAssessment"),
  },{
    path: 'fact-checking-applications-drafts',
    name: 'AdminFactCheckingDrafts',
    meta: { middleware: 'AuthTechnicalExpert', title: 'Fact Checking' },
    component: () =>
    import("@/views/admin/FactCheckingApplications/Index"),
  },{
    path: 'fact-checking-applications-graded',
    name: 'AdminFactCheckingGraded',
    meta: { middleware: 'AuthTechnicalExpert', title: 'Fact Checking' },
    component: () =>
    import("@/views/admin/FactCheckingApplications/Index"),
  },

  {
    path: 'checkpoints/:id',
    name: 'AdminCheckpoints',
    meta: { middleware: 'AuthAdmin|AuthJuryHackathon|AuthMentorDeveloper|AuthMentorPm', title: 'Чекпоинты Хакатона | Чекпоинт [id]' },
    component: () =>
    import("@/views/admin/Checkpoints/Index"),
  },{
    path: 'checkpoints/:id/show/:application_id',
    name: 'AdminCheckpointsShow',
    meta: { middleware: 'AuthAdmin|AuthJuryHackathon|AuthMentorDeveloper|AuthMentorPm', title: 'Чекпоинты Хакатона | Чекпоинт [id]' },
    component: () =>
    import("@/views/admin/Checkpoints/Show"),
  }
]

export default routes;
