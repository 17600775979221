export default {
  hello: "Salom",
  usd: "USD",
  uzs: "cум",
  "Кто может участвовать в конкурсе?": "Tanlovda kim ishtirok etishi mumkin?",
  "В конкурсе могут участвовать только граждане Узбекистана, не достигшие 30 летнего возраста в момент регистрации.":
    "Tanlovda faqat ro‘yxatdan o‘tish vaqtida 30 yoshga to‘lmagan O‘zbekiston fuqarolari ishtirok etishi mumkin.",
  "В конкурсе могут участвовать только граждане Узбекистана от 16 до 30 лет. При этом, участник должен быть не достигшим 30 летнего возраста в момент регистрации.":
    "Tanlovda faqat ro’yxatdan o’tish vaqtida 30 yoshga to’lmagan O’zbekiston fuqarolari ishtirok etishi mumkin.",
  "Почему в конкурсе могут участвовать лица только до 30 лет?":
    "Nima uchun tanlovda faqat 30 yoshgacha boʻlgan shaxslar qatnashishi mumkin?",
  "Согласно Постановлению Президента № ПП-357 от 23 августа 2022 года, конкурс нацелен на поддержку молодежных стартапов в сфере IT.":
    "Prezidentimizning 2022-yil 23-avgustdagi 357-sonli qaroriga asosan mazkur tanlov IT sohasidagi yoshlar startaplarini qo‘llab-quvvatlashga qaratilgan.",
  "Как проходить регистрацию гражданам РУз?":
    "O‘zbekiston Respublikasi fuqarolari uchun qanday ro‘yxatdan o‘tish kerak?",
  "Для участия в конкурсе все члены команд с гражданством Республики Узбекистан регистрируются на портале через единую информационную систему идентификации пользователей системы «Электронное правительство» (OneID).":
    "Tanlovda qatnashish uchun O‘zbekiston Respublikasi fuqaroligiga ega bo‘lgan barcha jamoa a’zolari portalda “Elektron hukumat” (OneID) tizimi foydalanuvchilarini identifikatsiyalashning yagona axborot tizimi orqali ro‘yxatdan oʻtkaziladi.",
  "Смогут ли иностранные граждане принять участие на конкурсе?":
    "Chet el fuqarolari tanlovda qatnasha oladimi?",
  "В основном направлении конкурса могут принимать участие исключительно граждане Республики Узбекистан, а в специальном направлении - также иностранные граждане, являющиеся сотрудниками резидентов IT Park.":
    "Tanlovning asosiy yo‘nalishida faqat O‘zbekiston Respublikasi fuqarolari, maxsus yo‘nalishda esa IT Park rezidentlari xodimlari bo‘lgan chet el fuqarolari qatnashishlari mumkin.",
  "Как проходить регистрацию иностранцам?":
    "Xorijliklar qanday roʻyxatdan oʻtishlari kerak?",
  "Иностранные граждане и лица без гражданства - команды, участвующие в специальном направлении, регистрируются через форму, доступную на портале.":
    "Xorijiy fuqarolar va fuqaroligi bo‘lmagan shaxslar – maxsus yo‘nalishda qatnashuvchi jamoalar portalda mavjud bo‘lgan shakl orqali ro‘yxatdan o‘tkaziladi.",
  "Может ли одна команда или участник участвовать в различных направлениях?":
    "Bitta jamoa yoki ishtirokchi turli yo‘nalishlarda qatnashishi mumkinmi?",
  "Одна команда может принять участие только в одном направлении конкурса.":
    "Bitta jamoa tanlovning faqat bitta yo‘nalishida qatnashishi mumkin.",
  "Как будет проводиться специальное направление?":
    "Maxsus yoʻnalish qanday oʻtkaziladi?",
  "Состоится презентация проектов по специальному направлению конкурса. Команды-участники специального направления разрабатывают прототип программного продукта в течение 72 часов на особых условиях, представляют и защищают свои проекты в ответственной организации.":
    "Tanlovning maxsus yoʻnalishi boʻyicha loyihalar taqdimoti boʻlib oʻtadi. Maxsus yo‘nalishda ishtirok etuvchi jamoalar maxsus shartlarda 72 soat ichida dasturiy mahsulot prototipini ishlab chiqadilar, mas’ul tashkilot qarshisida oʻz loyihalarini taqdim etadilar va himoya qiladilar.",
  "Является ли участие на конкурсе бесплатным?":
    "Tanlovda ishtirok etish bepulmi?",
  "Да, участие является бесплатным.": "Ha, ishtirok bepul.",
  "Можно ли командам принимать участие одновременно по двум и более категориям в основном направлении?":
    "Jamoalar asosiy yoʻnalishda bir vaqtning o‘zida ikki yoki undan ortiq toifada qatnasha oladimi?",
  "Участвовать разрешается только по 1 выбранной категории основного направления, а также в специальном направлении.":
    "Asosiy yoʻnalishning faqat 1 ta tanlangan toifasi boʻyicha, shuningdek, maxsus yo‘nalishda qatnashishga ruxsat beriladi.",
  "Как будет проходить онлайн голосование?":
    "Onlayn ovoz berish qanday amalga oshiriladi?",
  "Это будет общественное голосование на специальном портале, где голосующие будут авторизоваться через OneID. Голосование пройдет на основе 5 бальной системы: внешний вид проекта; функционал проекта; удобство проекта; готовность (полноценность) проекта и значимость проекта. Исходя из результатов голосования будут определены победители основных направлений.":
    "Bu maxsus portalda ommaviy ovoz berish bo‘lib, saylovchilar OneID orqali tizimga kirishadi. Ovoz berish 5 balllik tizim asosida o‘tkaziladi: loyihaning ko‘rinishi; loyihaning funksionalligi; loyihaning qulayligi; loyihaning tayyorligi (toʻliqligi) va loyihaning ahamiyati. Ovoz berish natijalariga ko‘ra, asosiy yo‘nalishlar g‘oliblari aniqlanadi.",
  "Проекты какой стадии могут подать заявку на конкурс?":
    "Tanlovga qanday bosqich loyihalari ariza topshirishi mumkin?",
  "Так как основной целью конкурса является определение экспорто-ориентированных стартапов и готовых к выходу на международные рынки, мы ожидаем стартапы с уже работающим MVP, у которого уже есть пользователи и продажи. К сожалению, стартапы на этапе идеи не смогут пройти первичный отбор.":
    "Tanlovning asosiy maqsadi eksportga yo‘naltirilgan startaplarni aniqlash va xalqaro bozorlarga kirishga tayyor bo‘lganligi sababli, biz allaqachon foydalanuvchilar va sotuvga ega bo‘lgan MVPʼga ega startaplarni kutmoqdamiz. Afsuski, g‘oya bosqichidagi startaplar asosiy tanlovdan o‘ta olmaydi",
  "Технологии на основе искусственного интеллекта":
    "Sun’iy intellekt texnologiyalari",
  "Цифровые технологии в социальной сфере":
    "Ijtimoiy yo‘nalishda raqamli texnologiyalar",
  "Технологии в сфере кибербезопасности":
    "Axborot va kiberxavfsizlik texnologiyalari",
  "Предпринимательство и финансовые технологии":
    "Tadbirkorlik va moliyaviy texnologiyalar",
  "Компьютерные и мобильные игры": "Kompyuter va mobil o‘yin dasturlari",
  "Специальное направление - Хакатон": "Maxsus yo‘nalish - Xakaton",
  "Приветствуем Вас на сайте конкурса President Tech Award! Пожалуйста, оставьте свои контактные данные по форме ниже. Мы отправим Вам уведомление о старте регистрации на конкурс.":
    '"President Tech Award" tanlovining veb-saytiga xush kelibsiz. Iltimos, quyidagi shaklda aloqa ma’lumotlaringizni qoldiring. Tanlovga ro‘yxatdan o‘tish boshlangani haqida sizga xabarnoma yuboramiz.',
  "Ваше ФИО:": "Sizning to'liq ismingiz:",
  ФИО: "FISh",
  "Контактный номер:": "Aloqa raqami:",
  "Электронная почта:": "Elektron pochta:",
  "Электронная почта": "Elektron pochta",
  Отправить: "Yuborish",
  "Благодарим Вас за проявленный интерес к конкурсу President Tech Award! <b>1 мая 2023</b> года откроется регистрация для всех желающих принять участие в конкурсе лучших стартапов. Желаем удачи!":
    '"President Tech Award" tanloviga qiziqish bildirganingiz uchun tashakkur! <b>2023-yil 1-maydan </b> boshlab eng yaxshi startaplar tanlovida ishtirok etishni xohlovchilarning barchasi uchun ro‘yxatdan o‘tish ochiladi. Omad!',
  Спасибо: "Rahmat",
  Главная: "Bosh sahifa",
  Войти: "Kirish",
  Направления: "Yo'nalishlar",
  Правила: "Qoidalar",
  Контакты: "Kontaktlar",
  "Технологии на основе искусственного интеллекта":
    "Sun’iy intellekt texnologiyalari",
  "Решения для различных отраслей на основе технологий искусственного интеллекта":
    "Sun’iy intellekt texnologiyalariga asoslangan turli xil sohalar uchun yechimlar",
  "Цифровые технологии в социальной сфере":
    "Ijtimoiy yo‘nalishda raqamli texnologiyalar",
  "Проекты, направленные на цифровизацию операционных процессов в образовании, здравоохранении, туризме, транспорте и логистике, электронный перевод государственных услуг или решение существующих проблем в социальных сферах":
    "Ta’lim, sog‘liqni saqlash, turizm, transport va logistika sohalarida operatsion jarayonlarini raqamlashtirish, davlat xizmatlarini elektron tarzga o‘tkazish yoki ijtimoiy sohalarda mavjud muammolarni hal etishga qaratilgan loyihalar",
  "Технологии в сфере кибербезопасности":
    "Axborot va kiber-xavfsizlik texnologiyalari",
  "Проекты, направленные на обеспечение безопасности программных продуктов и программно-аппаратных комплексов в различных сферах, предотвращение киберпреступлений":
    "Turli xil sohalarda dasturiy mahsulotlar va apparat-dasturiy komplekslarni xavfsizligini ta’minlash, kiberjinoyatlarni oldini olishga qaratilgan loyihalar",
  "Предпринимательство и финансовые технологии":
    "Tadbirkorlik va moliyaviy texnologiyalar",
  "Проекты, направленные на создание электронной коммерции, корпоративных решений и финансовых технологий для субъектов хозяйствования и хозяйственных объединений":
    "Tadbirkorlik subyektlari va xo‘jalik boshqaruv organlari uchun elektron tijorat, korporativ yechimlar va moliya texnologiyalarini yaratishga qaratilgan loyihalar",
  "Компьютерные и мобильные игры": "Kompyuter va mobil o‘yin dasturlari",
  "Проекты, направленные на разработку офлайн- или онлайн-игр для компьютеров и мобильных устройств и геймификацию процессов":
    "Kompyuter va mobil qurilmalar uchun offlayn yoki onlayn o‘yinlar ishlab chiqish hamda jarayonlarni geymifikatsiya etishga qaratilgan loyihalar",
  "Специальное направление - Хакатон": "Maxsus yo'nalish <br> - Hakaton",
  "72 часовой хакатон, где нужно разработать прототипа проекта на основе поставленных задач":
    "Vazifalar asosida loyiha prototipini ishlab chiqish kerak bo’lgan 72 soatlik hakaton",
  "Призовой фонд": "Mukofot fondi",
  "Общий призовой фонд 1 000 000 $":
    "Mukofot jamg'armasi jami 1 000 000 AQSh dollari",
  "5 Основных направлений:": "5 ta asosiy yo'nalishlar:",
  "1 место": "1-o'rin",
  "2 место": "2-o'rin",
  "3 место": "3-o'rin",
  "Специальное направление": "Maxsus yo'nalish",
  "Необходимые материалы для заполнения заявки":
    "Arizani to'ldirish uchun zarur bo'lgan materiallar",
  "Аккаунт в OneID для регистрации":
    "Ro'yxatdan o'tish uchun “OneID”da akkaunt",
  "Видео описывающее проект": "Loyihani tavsiflovchi video",
  "(не более 3 минут)": "(3 daqiqadan ko'p bo'lmagan)",
  "Презентация проекта (PDF формат) - загрузка файла или ссылки на файл":
    "Loyiha taqdimoti (PDF formati) - faylni yoki faylga havolani yuklash",
  "Ссылка на прототип проекта (MVP)": "Loyiha prototipiga havola (MVP)",
  "Ссылка проекта на GitHub": "GitHub-da loyiha havolasi",
  "Условия участия": "Ishtirok etish shartlari",
  "Количество заявок": "Arizalar soni",
  "Допускается подача заявки на участие в конкурсе только по 1 основному и спец. направлению.":
    "Tanlovda ishtirok etish uchun faqat 1 ta asosiy va maxsus yo‘nalish bo‘yicha ariza topshirishga ruxsat beriladi.",
  "Количество участников в команде": "Jamoa a'zolari soni",
  "Для участия в конкурсе состав команд должен состоять из 3-8 человек по основным направлениям и 3-5 человек по специальному направлению.":
    "Tanlovda ishtirok etish uchun jamoalar tarkibi asosiy yo‘nalishlar bo‘yicha 3-8 kishidan, maxsus yo‘nalishda esa 3-5 kishidan iborat bo‘lishi kerak.",
  "Стоимость участия": "Ishtirok etish narxi",
  "Плата за участие в конкурсе не взимается. (Участие в конкурсе – бесплатно)":
    "Tanlovda ishtirok etish uchun to'lov olinmaydi. (tanlovda ishtirok etish – bepul)",
  "Покрытие расходов": "Xarajatlarni qoplash",
  "Расходы, связанные с реализацией проектов, разработанных в рамках конкурса, не возмещаются.":
    "Tanlov doirasida ishlab chiqilgan loyihalarni amalga oshirish bilan bog‘liq xarajatlar qoplanmaydi.",
  "Изменения в команде": "Jamoa o'zgarishi",
  "Изменения в составе команды возможны при условии согласования с организационным комитетом.":
    "Jamoa tarkibiga o'zgartirishlar tashkiliy qo'mita bilan kelishilgan holda amalga oshirilishi mumkin",
  "Условия отбора участников в состав команды":
    "Ishtirokchilarni jamoaga  tanlash shartlari",
  "В основном направлении конкурса могут участвовать граждане Узбекистана, не достигшие 30 лет на день подачи заявки. В специальном направлении могут участвовать иностранные граждане и лица без гражданства, работающие в компаниях резидентах IT Park.":
    "Tanlovning asosiy yo‘nalishida ariza topshirilgan kuni 30 yoshga to‘lmagan O‘zbekiston fuqarolari ishtirok etishlari mumkin. Maxsus yo‘nalishda IT-Park rezidentlarida ishlaydigan chet el fuqarolari va fuqaroligi bo‘lmagan shaxslar ham ishtirok etishlari mumkin.",
  Таймлайн: "Vaqt jadvali",
  "Предварительный отбор": "Dastlabki tanlov",
  "Онлайн оценивание проектов": "Loyihani onlayn baholash",
  "Онлайн голосование": "Onlayn ovoz berish",
  "Прием заявок": "Arizalarni qabul qilish",
  Bootcamp: "Bootcamp",
  "Demo Day": "Demo Day",
  Награждение: "Taqdirlash marosimi",
  Галерея: "Galereya",
  "Показать все фотографии": "Barcha fotosuratlarni ko'rsatish",
  Новости: "Yangiliklar",
  "Спонсоры и партнеры": "Homiylar va hamkorlar",
  "Часто задаваемые вопросы": "Ko'p so'raladigan savollar",
  "за 1 место": "1-o'rin uchun",
  "Кто может участвовать в конкурсе?":
    "Tanlovda kimlar ishtirok etishi mumkin?",
  "Сколько человек должны быть в команде и должны ли все подавать заявки?":
    "Bir jamoada qancha odam bo'lishi kerak va barcha ariza topshirishi kerakmi?",
  "Что нужно для подачи заявки?": "Ariza topshirish uchun nima kerak?",
  "Каким должно быть приложение для подачи заявки?":
    "Ariza topshirish uchun ilova qanday bo'lishi kerak?",
  "Кто будет оценивать проекты?": "Loyihalarni kim baholaydi?",
  "Может ли один участник участвовать в различных командах?":
    "Bitta ishtirokchi turli jamoalarda qatnashishi mumkinmi?",
  "Должны ли студенты сами разработать приложение?":
    "Talabalar dasturni o'zlari ishlab chiqishlari kerakmi?",
  "Можно ли участвовать с проектом не подходящим под категории?":
    "Kategoriyaga mos kelmaydigan loyiha bilan qatnasha olamanmi?",
  "приветствуем вас на сайте конкурса President Tech Award":
    "President Tech Award",
  "veb-saytiga xush kelibsiz": "Welcome to the President Tech Award website",
  "ваше ФИО": "Full name",
  "контактный номер": "Contact number",
  "электронная почта": "Email",
  отправить: "Submit",
  "благодарим вас за проявленный интерес к конкурсу President tech award":
    "Thank you for your interest in the President Tech Award",
  "qiziqganingiz uchun tashakkur": "Thank you for your interest",
  "1 мая 2023 года откроется регистрация для всех желающих принять участие в конкурсе лучших стартапов":
    "Registration for the best startup competition will open on May 1, 2023, for all those who wish to participate",
  "eng yaxshi startaplar tanlovida ishtirok etishni xohlovchilar uchun ro‘yxatdan o‘tish, 2023-yil 1-maydan boshlanadi.":
    "Registration for the best startup competition will open on May 1, 2023, for all those who wish to participate",
  "желаем удачи": "Good luck",
  "sizga omad": "Wishing you success",
  спасибо: "Thank you",
  "Конкурс лучших стартапов с общим призовым фондом в":
    "Eng yaxshi startaplar uchun tanlov. Umumiy mukofot jamg'armasi",
  "President Tech Award – это конкурс на лучшие цифровые продукты по 5 основным и 1 специальному направлению. Целью которого является привлечь внимание молодежи к сфере IT-технологий и вдохновить их на создание инновационных решений и разработок, способных конкурировать на мировом рынке.":
    "President Tech Award – bu 5 ta asosiy va 1 ta maxsus yoʻnalish boʻyicha eng yaxshi dasturiy mahsulotlar uchun tanlovdir. Bundan ko‘zlangan maqsad yoshlar e’tiborini IT-texnologiyalari sohasiga jalb qilish va ularni jahon bozorida raqobatlasha oladigan innovatsion yechimlar va ishlanmalarni yaratishga undashdan iborat.",
  "Видео описывающее проект (не более 3 минут)":
    "Loyihani tavsiflovchi video(3 daqiqadan ko'p bo'lmagan)",
  "20 Марта, 2023": "20 Mart, 2023",
  "26 Марта, 2023": "26 Mart, 2023",
  "23 Марта, 2023": "23 Mart, 2023",
  "Roadshow: Встреча с молодежью в рамках конкурса «mGovAward»":
    "Roadshow: mGovAward tanlovi doirasida yoshlar bilan uchrashuv",
  "Во всех областях Узбекистана проводятся семинары mGovAward":
    "mmGovAward seminarlari O‘zbekistonning barcha hududlarida o‘tkazilmoqda",
  "Roadshow в рамках конкурса «mGovAward» продолжается":
    "mGovAward tanlovi doirasidagi Roadshow davom etmoqda",
  "Показать все новости": "Barcha yangiliklarni ko'rsatish",
  Телефон: "Telefon",
  "К вам поступил запрос от fullname на вступление в команду.":
    "Sizga <b>{fullname}</b> jamoaga qo'shilish uchun so'rov keldi.",
  "Ваш запрос на вступление в команду team_name отправлен. Ожидайте решение.":
    "<b>{team_name}</b> jamoasiga qo'shilish so'rovingiz yuborildi. Qarorni kuting.",
  "Вы приняли fullname в свою команду.":
    "Siz <b>{fullname}</b>ni jamoangizga qabul qildingiz.",
  "Поздравляем! Вы приняты в команду team_name!":
    "Tabriklaymiz! Siz <b>{team_name}</b> jamoasiga qabul qilindingiz!",
  "Вы отказали fullname на вступление в вашу команду.":
    "Siz <b>{fullname}</b>ni jamoangizga qo'shilishni rad etgansiz.",
  "Вам отказали на вступление в команду team_name.":
    "Sizga <b>{team_name}</b> jamoasiga kirish rad etildi.",
  "Вы удалили fullname из вашей команды.":
    "Siz <b>{fullname}</b>ni jamoangizdan olib tashladingiz.",
  "Вас удалили из команды team_name.":
    "Sizni <b>{team_name}</b> jamoasidan olib tashlashdi.",
  "fullname покинул вашу команду.": "<b>{fullname}</b> jamoangizni tark etdi.",
  "Вы покинули команду team_name.":
    "Siz <b>{team_name}</b> jamoasini tark etdingiz.",
  "Ссылка на Livechat President Tech Award":
    "President Tech Award Livechat uchun havola",
  "Ссылка на телеграм канал конкурса President Tech Award":
    "President Tech Award tanlovining telegram kanaliga havola",
  "Ваша заявка еще не сформирована.<br> Обратитесь к капитану команды.":
    "Tanlovda ishtirok etish uchun arizangiz hali shakllanmagan. Jamoa sardoriga murojaat qiling.",
  "Ваша заявка на участие в основном направлении оформлена и передана на рассмотрение.":
    "Asosiy yo‘nalishda ishtirok etish uchun arizangiz to‘ldirildi va ko‘rib chiqish uchun topshirildi.",
  "Ваша заявка на участие в Хакатоне оформлена и передана на рассмотрение.":
    "Xakatonda ishtirok etish uchun arizangiz toʻldirildi va koʻrib chiqish uchun yuborildi.",
  "Поздравляем! Ваша заявка на участие в основном направлении конкурса подтверждена.":
    "Tabriklaymiz! Tanlovning asosiy yo‘nalishida ishtirok etish uchun arizangiz tasdiqlandi.",
  "Поздравляем! Ваша заявка на участие в Хакатоне подтверждена.":
    "Tabriklaymiz! Xakatonda ishtirok etish uchun arizangiz tasdiqlandi.",
  "Ваша заявка на участие в Хакатоне возвращена на доработку.":
    "Sizning Xakatonda ishtirok etish uchun arizangiz kamchiliklar bor sababli qaytarildi.",
  "Ваша заявка на участие в основном направлении возвращена на доработку.":
    "Sizning asosiy yo‘nalishda ishtirok etish uchun arizangiz kamchiliklar bor sababli qaytarildi.",
  "Вы не сможете удалить команду пока в ней находятся другие участники!":
    "Siz Jamoani o'chira olmaysiz, agar uni ichida boshqa a'zolar bo'lsa!",
  Внимание: "Diqqat",
  "Для граждан Республики Узбекистан":
    "O‘zbekiston Respublikasi fuqarolari uchun",
  "Для иностранных граждан": "Chet el fuqarolari uchun",
  "Нет аккаунта?": "Sahifangiz yo‘qmi?",
  Зарегистрироваться: "Ro‘yxatdan o‘tish",
  "Регистрация граждан Республики Узбекистан проходит через Единая система идентификации (OneID).":
    "O‘zbekiston Respublikasi fuqarolarini ro‘yxatdan o‘tkazish yagona identifikatsiya tizimi (OneID) orqali amalga oshiriladi.",
  Авторизация: "Avtorizatsiya",
  "публичной оферты": "",
  "Нажимая кнопку “Авторизация”, вы принимаете условия":
    "“Avtorizatsiya” tugmasini bosish orqali siz shartlarni qabul qilasiz",
  "Зарегистрироваться на участие в конкурсе President Tech Award":
    "President Tech Award Tanlovida ishtirok etish uchun ro‘yxatdan o‘ting",
  ПИНФЛ: "Jismoniy shaxsning shaxsiy identifikatsion raqami",
  Пол: "Jinsi",
  "Дата рождения": "Tug‘ilgan sanasi",
  "Место проживания (Регион)": "Turar joyi (viloyat)",
  "Место проживания (Район)": "Turar joyi (tuman)",
  "Номер телефона": "Telefon raqami",
  "Роль участника": "Ishtirokchining roli",
  "текущее место работы/учебы": "hozirgi ish/o‘qish joyi",
  "Код отправлен на ваш номер в смс":
    "Kod sizning raqamingizga SMS orqali yuborildi",
  "Вход для иностранных граждан": "Chet el fuqarolari uchun kirish",
  Пароль: "Parol",
  "Повторите пароль": "Parolni takrorlang",
  "Мой профиль": "Mening profilim",
  "На рассмотрении": "Ko‘rib chiqilmoqda",
  "Дата регистрации": "Ro‘yxatdan o‘tish sanasi",
  "Дата рождения:": "Tug‘ilgan sana:",
  "Гражданство:": "Fuqarolik:",
  "Место проживания:": "Turar joyi:",
  "Номер телефона:": "Telefon raqami:",
  "Роль участника:": "Ishtirokchining roli:",
  "Текущее место работы:": "Hozirgi ish joyi:",
  "Письмо подтверждающее то, что вы работаете в компании резидента IT Park":
    "IT Park rezidentlari kompaniyasida ishlayotganingizni tasdiqlovchi xat",
  "Копия паспорта:": "Pasport nusxasi:",
  Уведомления: "Xabarnomalar",
  Сообщество: "Hamjamiyat",
  "часто задаваемые вопросы": "ko‘p beriladigan savollar",
  выход: "chiqish",
  "Заявки команд на конкурс": "Jamoalarning tanlovga arizalari",
  "ID команды": "Jamoa IDsi",
  "Название Команды": "Jamoa nomi",
  "дата регистрации (от)": "ro'yxatdan o'tish sanasi (dan)",
  "дата регистрации (до)": "ro'yxatdan o'tish sanasi (gacha)",
  Регион: "Viloyat",
  "направление конкурса": "tanlov yo'nalishi",
  "статус заявки": "ariza holati",
  "количество участников": "Ishtirokchilar soni:",
  Статус: "holat",
  подтвержден: "tasdiqlangan",
  "возвращено на доработку": "qayta ko'rib chiqish uchun qaytarildi",
  "на рассмотрении": "ko'rib chiqilmoqda",
  "логотип команды": "Jamoa logotipi:",
  "название команды": "Jamoa nomi:",
  "регион команды": "Jamoa mintaqasi:",
  "Роль участника": "Ishtirokchining roli",
  "Регистрация иностранных граждан":
    "Chet el fuqarolarini ro'yxatdan o'tkazish",
  Выберите: "Tanlang",
  "количество участников": "Ishtirokchilar soni:",
  "Номер телефона:": "Telefon raqami:",
  "электронная почта": "elektron pochta",
  статус: "holat",
  "Список участников": "Ishtirokchilar ro'yxati",
  ПИНФЛ: "Jismoniy shaxsning shaxsiy identifikatsion raqami",
  ФИО: "FISh",
  Пол: "Jinsi",
  "Гражданство:": "Fuqarolik:",
  Далее: "Davom etish",
  Закрыть: "Yopish",
  "Эта электронная почта уже зарегистрирована на платформе":
    "Ushbu elektron pochta allaqachon platformada ro'yxatdan o'tgan",
  Внимание: "Diqqat",
  Выход: "Chiqish",
  FAQ: "Ko'p so'raladigan savollar",
  "E-mail": "Elektron pochta",
  Подтвержден: "Tasdiqlangan",
  Мужчина: "Erkak",
  Женщина: "Ayol",
  "Текущее место работы/учебы": "Hozirgi ish/o‘qish joyi",
  "Текущее место работы": "Hozirgi ish joyi",
  "Основная команда": "Asosiy jamoa",
  Хакатон: "Xakaton",
  Название: "Nomi",
  "Ссылка на восстановление аккаунта отправлено на вашу почту!":
    "Profilingizni tiklash uchun havola elektron pochtangizga yuborildi!",
  "Восстановить аккаунт": "Profilni qayta tiklash",
  "Забыли пароль?": "Parolni unutdingizmi?",
  "Регион команды": "Jamoa hududi",
  "Название проекта": "Loyiha nomi",
  "Описание проекта на узбекском": "Loyihaning o'zbek tilida tavsifi",
  "Описание проекта на русском": "Loyihaning rus tilida tavsifi",
  "Описание проекта на английском": "Loyihaning ingliz tilida tavsifi",
  "Ссылка на видеозапись, описывающий проект (до 3 минут)":
    "Loyihani tavsiflovchi videoga havola (3 daqiqagacha)",
  "Презентация проекта (файл в формате PDF до 20 Мб)":
    "Loyiha taqdimoti (PDF fayli 20 Mb gacha)",
  "Ссылка на GitHub проекта (Нужно открыть доступ к данному аккаунту - awardsgovuz)":
    "GitHub loyihasiga havola (Ushbu akkaungta loyihani  ko'rishga ruxsat berish kerak - awardsgovuz)",
  "Ссылка на GitHub проекта": "GitHub loyihasiga havola",
  "Доступы на прототип проекта (MVP)": "Loyiha prototipiga (MVP) kirish",
  "Например, логин и пароль": "Masalan, login va parol",
  "Логотип проекта в высоком качестве (в формате jpeg, jpg, png до 5 Мб)":
    "Loyiha logotipi yuqori sifatda (jpeg, jpg, png formatida 5 Mb gacha)",
  "Отправить заявку": "Ariza yuborish",
  "Выберите файл": "Faylni tanlang",
  Участники: "A'zolar",
  "Запросы на вступление": "Qo'shilish so'rovlari",
  "Заявка на конкурс": "Tanlov uchun ariza",
  "Информация о команде": "Jamoa haqida ma'lumot",
  Сохранить: "Saqlash",
  "Место проживания (Регион)": "Yashash joyi (viloyat)",
  "Место проживания (Район)": "Yashash joyi (tumani)",
  Редактирование: "Tahrirlash",
  "Место проживания": "Yashash joyi",
  "Дополнительный номер телефона": "Qo'shimcha telefon raqami",
  "Моя команда": "Mening jamoam",
  "Отправлять приглашение": "Taklifnoma yuborish",
  Действие: "Harakat",
  Капитан: "Kapitan",
  Удалить: "Oʻchirish",
  Принято: "Qabul qilingan",
  "Логотип команды": "Jamoa logotipi",
  "Название команды": "Jamoa nomi",
  "История действий": "Harakatlar tarixi",
  Исполнитель: "Ijrochi",
  "Дата действия": "Sana",
  "Статус действия": "Status",
  Комментарий: "Izoh",
  "Контактный номер": "Aloqa raqami",
  "Добавить контактный номер": "Aloqa raqamini qo'shish",
  "Вы уверены, что хотите удалить этого участника?":
    "Bu aʼzoni oʻchirmoqchimisiz?",
  Да: "Ha",
  Нет: "Yo'q",
  Гражданство: "Fuqarolik",
  "Копия паспорта (в формате PDF до 15 Мб)":
    "Pasport nusxasi (PDF formatida 15 Mb gacha)",
  "Письмо подтверждающее о том, что вы работаете в компании резидента IT Park":
    "IT Park rezident kompaniyasida ishlayotganingizni tasdiqlovchi xat",
  "Код или электронная почта могли быть введены неправильно":
    "Kod yoki elektron pochta noto'g'ri kiritilgan bo'lishi mumkin",
  "Код отправлен на ваш E-mail": "Kod sizning elektron pochtangizga yuborildi",
  "Введите код": "Kodni kiriting",
  Назад: "Orqaga",
  "Копия паспорта": "Pasport nusxasi",
  Открыть: "Ochish",
  Изменить: "O'zgartirish",
  "Ваш профиль возвращено на доработку.":
    "Sizning profilingiz qayta ko'rib chiqish uchun qaytarildi.",
  Причина: "Sababi",
  "Поздравляем! Ваш профиль подтвержден.":
    "Tabriklaymiz! Profilingiz tasdiqlandi.",
  "Изменить пароль": "Parolni o'zgartirish",
  "Текущий пароль": "Joriy parol",
  "Новый пароль": "Yangi parol",
  "Повторите новый пароль": "Yangi parolni takrorlang",
  "У вас еще нет команды!": "Sizda hali jamoa yo'q!",
  "Вы можете создать новую команду или присоединиться к команде":
    "Siz yangi jamoa yaratishingiz yoki jamoaga qo'shilishingiz mumkin",
  "Создать команду": "Jamoa yaratish",
  "Присоединиться к команде": "Jamoaga qo'shilish",
  Логотип: "Logotip",
  "Введите TEAM ID для вступления в команду":
    "Jamoaga qo'shilish uchun TEAM ID ni kiriting",
  "Team ID": "Team ID",
  Поиск: "Izlash",
  "Ваш результат поиска по TEAM ID: number":
    "TEAM ID qidiruvingiz natijasi: number",
  "Отправить запрос": "So'rov yuborish",
  "Количество участников должно быть от 3 до 5 человек":
    "A'zolar soni 3 dan 5 kishigacha bo'lishi kerak",
  "Количество участников должно быть от 3 до 8 человек":
    "A'zolar soni 3 dan 8 kishigacha bo'lishi kerak",
  "Показать еще": "Ko'proq ko'rsatish",
  "Участники достигшие возраста 30 лет на момент регистрации не могут участвовать в конкурсе.":
    "Ro'yxatdan o'tish vaqtida 30 yoshga to'lgan ishtirokchilar tanlovda qatnasha olmaydi.",
  "Запросы на удаление": "O'chirish uchun so'rovlar",
  "Капитан команды": "Jamoa sardori",
  "Причина удаления": "O'chirish sababi",
  Отклонен: "Rad etilgan",
  "Причина отклонения": "Rad etish sababi",
  "Дата и время": "Sana va vaqt",
  "Ваш запрос на удаление команды отправлен на рассмотрение.":
    "Jamoani o'chirib tashlash haqidagi so‘rovingiz ko‘rib chiqish uchun yuborildi.",
  "Ваша команда team_name удалена.":
    "Sizning jamoangiz {team_name} o'chirildi.",
  "Ваш запрос на удаление команды отклонен.":
    "Jamoani o'chirib tashlash haqidagi so‘rovingiz rad etildi.",
  redactor: "Админ по PR",
  registration: "Админ по заявкам",
  admin: "Супер админ",
  Игра: "O‘yin",
  Играть: "O‘yin boshlash",
  ПРОДОЛЖИТЬ: "DAVOM ETISH",
  Тест: "Test",
  "Выберите понравившийся проект из категории direction_name":
    "Yo‘nalishdan o'zingiz yoqtirgan loyihani tanlang<br> “{direction_name}”",
  "Телеграм канал": "Telegram kanali",
  "Телеграм группа": "Telegram guruhi",
  "Шаблон презентации:": "Taqdimot shabloni:",
  файл: "fayl",
  "Процесс создания и формирования команды для участия в конкурсе завершен 30 июня 2023 года":
    "Musobaqada ishtirok etish uchun jamoani yaratish va shakllantirish jarayoni 2023-yil 30-iyun kuni yakunlandi",
  "Подача заявки на участие в конкурсе завершена 30 июня 2023 года":
    "Tanlovda ishtirok etish uchun ariza qabul qilish 2023-yil 30-iyun kuni yakunlandi",
  "Вы не можете удалить участника команды. В команде должно быть минимум 3 участника.":
    "Siz jamoa a'zosini chetlashtira olmaysiz. Jamoada kamida 3 kishi bo'lishi kerak.",
  "Мои запросы на вступление": "Mening jamoaga qo'shilish uchun so'rovlarim",
  "Отправить новый запрос": "Yangi so'rov jo'natish",
  "Вы можете присоединиться к команде": "Siz jamoaga qo'shilishingiz mumkin",
  "Ваш запрос на добавление нового участника в команду отправлен на рассмотрение":
    "Ваш запрос на добавление нового участника в команду отправлен на рассмотрение",
  Принять: "Qabul qilish",
  Отказать: "Rad etish",
  "История заявок": "Ariza tarixi",
  "Дата создания": "Yaratilgan sana",
  "Тип запроса": "So'rov turi",
  Статус: "Status",
  "Не прошел": "O'tmagan",
  jury: "Jury",
  "Не состоит ни в одной команде": "Hech qanday jamoa a'zosi emas",
  "Процесс подачи заявки для участия в конкурсе завершен 31 августа 2023 года":
    "Tanlovda ishtirok etish uchun ariza topshirish jarayoni 2023-yil 31-avgustda yakunlandi",
  "Оценки жюри": "Hakamlar bahosi",
  "Ваш проект еще не оценили члены жюри":
    "Loyihangiz hali hakamlar a’zolari tomonidan baholanmagan",
  "Ожидайте оценок": "Baholarni kuting",
  Посмотреть: "Посмотреть",
  "Средний балл": "O'rtacha ball",
  "В ожидании": "Kutish jarayonida",
  "Актуальность предлагаемого стартап-проекта":
    "Taklif qilinayotgan loyihaning dolzarbligi",
  "Оценка достижимости результатов стартап-проекта":
    "Loyihaning natijalarga erishish imkoniyatlarini baholash",
  "Оценка потенциальных конкурентных преимуществ проекта":
    "Bozorda mahsulot yoki xizmatga bo‘lgan talabning mavjudligini baholash",
  "Оценка востребованности продукта или услуги на рынке":
    "Loyihaning salohiyatli raqobat afzalliklarini baholash",
  "Завершенность продукта": "Loyihaning yakunlanganligi",
  Функциональность: "Funktsionallik",
  Работоспособность: "Loyiha prototipi ishlashi",
  "Интерфейс и дизайн": "Interfeys va dizayni",
  Интеграция: "Integratsiya",
  "Оценка потенциала команды": "Jamoaning imkoniyatlari",
  Укомплектованность: "Jamoa tarkibi",
  Презентация: "Taqdimot",
  "Оценка качества ответов на вопросы жюри о проекте":
    "Hakamlar hay’atining loyiha bo‘yicha berilgan savollariga javoblar sifatini baholash",
  "Командная дисциплина": "Jamoa intizomi",
  "Техническая реализация": "Texnik amalga oshirilganligi",
  "Использование современных технологий и востребованных языков программирования":
    "Zamonaviy texnologiyalar va dolzarb dasturlash tillaridan foydalanilganini baholash",
  "правильно подобранная архитектура для решения задачи и возможности масштабирования":
    "Loyihani amalga oshirish uchun to‘g‘ri tanlangan arxitektura va kengaytirish imkoniyatlarini baholash",
  "jury-hackathon": "Hakaton jurysi",
  hackathon: "Hakaton",
  "Не отобраны 2Х": "O'tmagan 2Х",
  "Не отобраны 3Х": "O'tmagan 3Х",
  "Онлайн оценка": "Onlayn baholash",
  "Жюри неотобранные": "Jury tanlanmagan",
  "Жюри отобранные": "Jury tanlangan",
  Оценки: "Оценки",
  "1-этап": "1-bosqich",
  "2-этап": "2-bosqich",
  Сертификаты: "Sertifikatlar",
  "Сертификат участника Хакатон": "Hackathon ishtirokchi sertifikati",
  Скачать: "Yuklash",
  Финал: "Final",
  "Результаты жюри": "Hakamlar bahosi",
  Направление: "Yo‘nalish",
  "Количество заявок": "Arizalar soni",
  "Направление конкурса": "Musobaqa yo'nalishi",
  "Средняя оценка": "O'rtacha baho",
  "Средний балл (1 этап)": "Oʻrtacha baho (1-bosqich)",
  "Средний балл (2 этап)": "Oʻrtacha baho (2-bosqich)",
  "макс. 50 баллов": "maks. 50 ball",
  "Перейти на онлайн голосование": "Onlayn ovoz berishga o’tish",
  Голосовать: "Ovoz berish",
  дней: "kun",
  часов: "soat",
  минут: "daqiqa",
  секунд: "soniya",
  "Стартовал этап онлайн-голосования конкурса President Tech Award с общим призовым фондом $1 млн!":
    "Umumiy sovrin jamgʻarmasi <span>$1 millionni</span> tashkil qiluvchi “President Tech Award” tanlovining onlayn ovoz berish bosqichi boshlandi!",
  "Каждый голос очень важен! Присоединяйтесь к общественному голосованию, зарегистрировавшись через систему MyID. Поддержите проекты и помогите определить победителей!":
    "<span>Har bir ovoz juda muhim!</span> MyID tizimi orqali roʻyxatdan oʻtib ommaviy ovoz berishga qoʻshiling. Loyihalarni qoʻllab-quvvatlang va gʻoliblarni aniqlashga yordam bering!",
  "25 лучших команд, отобранных по основным направлениям конкурса ждут, чтобы вы оценили их проекты по следующим критериям:":
    "Tanlovning asosiy yoʻnalishlari boʻyicha saralangan 25 ta eng yaxshi jamoalar oʻz loyihalarini quyidagi mezonlar boʻyicha baholashingizni kutmoqda:",
  "Внешний вид (интерфейс и дизайн)": "Tashqi koʻrinish (interfeys va dizayn)",
  Удобство: "Qulaylik",
  Завершенность: "Tugallanganlik",
  Актуальность: "Dolzarblik",
  "Результаты онлайн голосования": "Onlayn ovoz berish natijalari",
  Команда: "Jamoa",
  Балл: "Ball",
  Голоса: "Ovozlar soni",
  Голосование: "Ovoz berish",
  "Выберите категорию проекта": "Loyiha toifasini tanlang",
  "Презентация проекта": "Loyiha taqdimoti",
  "Доступы к системе": "Tizimga kirish",
  "Логотип проекта": "Loyiha logotipi",
  "Оцените выбранный проект": "Tanlangan loyihaga baho bering",
  "Проставьте каждому из пунктов свою оценку. Исходите из вашего личного опыта.":
    "Har bir bandga baho bering. Shaxsiy tajribangizga asoslanib.",
  Дизайн: "Dizayn",
  Функционал: "Funktsional",
  Удобство: "Qulaylik",
  Готовность: "Tayyorlik",
  "Дата оценки": "Baholangan sana",
  "По критериям": "Kriteriyalar bo'yicha",
  "Посмотреть файл": "Faylni ko’rish",
  "Регистрация на голосование": "Ovoz berish uchun ro'yxatdan o'tish",
  "Пройдите регистрацию и оставьте свой голос за интересный для вас проект":
    "Ro'yxatdan o'ting va sizni qiziqtirgan loyiha uchun ovozingizni qoldiring",
  "Вы уже проголосовали": "Siz ovoz bergansiz",
  "Ссылка на видеозапись, описывающий проект":
    "Loyihani tavsiflovchi videoga havola",
  Проект: "Loyiha",
  "Как будут определены победители основного направления конкурса?":
    "Tanlovning asosiy yo'nalishi g'oliblari qanday aniqlanadi?",
  "Победители будут определены на этапе онлайн голосования, голосование пройдет на основе 5 бальной системы: внешний вид проекта; функционал проекта; удобство проекта; готовность (завершенность) проекта и значимость проекта. Победителями по итогам голосования будут являться проекты набравшие максимальный балл.":
    "G‘oliblar onlayn ovoz berish bosqichida aniqlanadi, ovoz berish 5 balllik tizim bo‘yicha o‘tkaziladi: loyihaning ko‘rinishi; loyihaning funksionalligi; loyihaning qulayligi; loyihaning tayyorligi (to'liqligi) va loyihaning ahamiyati. Ovoz berishda maksimal ball to‘plagan loyihalar g‘olib deb topiladi.",
  "Видеоинструкция по голосованию": "Ovoz berish bo'yicha video ko'rsatmalar",
  Подробнее: "Batafsil",
  "Ознакомление с финалистами": "Finalchilar bilan tanishing",
  "Ovoz berish vaqti tugadi": "Ovoz berish vaqti tugadi",
  "President Tech Award —":
    "President Tech Award — это конкурс на лучшие программные продукты по 5 основным и 1 специальному направлению. Целью которого является привлечь внимание молодёжи к сфере IT-технологий и вдохновить их на создание инновационных решений и разработок, способных конкурировать на мировом рынке.",
  LastWinners: "O'tgan mavsum g'oliblari:",
  seasontitle: "Технологии на основе искусственного интеллекта:",
  Настройки: "Sozlamalar",
  Редактировать: "Tahrirlash",
  "Место прописки": "Ro‘yxatdan o‘tish joyi",
  Добавить: "Qo‘shish",
  "Технологии в сфере информационной и кибербезопасности":
    "Axborot va kiber-xavfsizlik texnologiyalari",
  Регистрация: "Ro‘yxatdan o‘tish",
  "President Tech Award —":
    "Prezident Tech Award — bu 5 ta asosiy va 1 ta maxsus yo‘nalish bo‘yicha eng yaxshi dasturiy mahsulotlar uchun tanlov. Tanlovdan maqsad — yoshlar e’tiborini IT-texnologiyalar sohasiga jalb qilish va ularni jahon bozorida raqobatlasha oladigan innovatsion yechimlar va ishlanmalarni yaratishga ilhomlantirishdir.",
  "Micro-SaaS — это подход к разработке ПО, который использует небольшие, независимые сервисы для создания приложений. Основные преимущества включают гибкость, масштабируемость и упрощение обслуживания.<br/> AdTech — это технологии для управления и оптимизации цифровой рекламы. Включает инструменты для таргетинга, ретаргетинга и автоматизации рекламных кампаний, с целью повышения эффективности и ROI.":
    "Micro-SaaS - bu ilovalarni yaratish uchun kichik, mustaqil xizmatlardan foydalanadigan dasturiy ta'minotni ishlab chiqish yondashuvi. Asosiy afzalliklari moslashuvchanlik, kengaytirilish imkoniyati va texnik xizmat ko'rsatishni soddalashtirishni o'z ichiga oladi.<br/> AdTech - bu raqamli reklamani boshqarish va optimallashtirish uchun texnologiyalarni anglatadi. U samaradorlik va ROI-ni oshirish uchun reklama kampaniyalarini maqsadli qilish, qayta maqsadli qilish va avtomatlashtirish vositalarini o'z ichiga oladi.",
  "Специальное направление: Хакатон": "Maxsus yo'nalish: Hakaton",
  "Технологические решения в сфере Micro-SaaS и AdTech":
    "Micro-SaaS va AdTech sohasidagi texnologik yechimlar",
  "Tahrirchi — проект":
    "Tahrirchi — oʻzbek tilida yozishni osonlashtirishga mo’ljallangan loyiha. Dastur o‘zbek tilidagi matnlardagi imlo, kontekstual, stilistik va grammatik xatolarni tekshiradi.",
  "Docum.ai — проект":
    "Docum.ai — sun'iy intellekt yordamida mijozlarga xizmat ko'rsatishni to'liq avtomatlashtiruvchi loyiha.",
  "Alama — платформа":
    "Alama — bu mahalliy tillardagi onlayn kurs platformasi. Dastur sun'iy intellektdan foydalanib, dunyodagi eng yaxshi onlayn kurslardagi matn va nutqni mahalliy tillarga tarjima qiladi.",
  "Mentalaba — проект":
    "Mentalaba — talabalarga Oliy ta’lim muassasalarini topish, o‘rganish va taqqoslashda o‘zlari uchun eng yaxshi maktabni aniqlashda yordam beruvchi loyiha.",
  "Lalu— мобильная платформа":
    "Lalu — bu yosh va homilador onalar uchun mobil platforma bo’lib, undan foydalanuvchilar ishonchli ma'lumotni topishi  va homiladorlik va bolalik davrida sog'lig'ingizni kuzatishi mumkin.",
  "Uztrip— помощь":
    "Uztrip — tashrif buyurish uchun eng yaxshi joylar, mehmonxonalar, restoranlar, diqqatga sazovor joylarni  topishda yordam beradi.",
  "ALATOR — проект":
    "ALATOR — xususiy va davlat sektorlari tomonidan ishlab chiqarilgan axborot tizimlari va resurslarida aniqlangan zaifliklar, kibertahdidlar, kiberhujumlar va boshqa zararli faoliyatlarni aniqlash va oldini olish imkonini taqdim etuvchi loyiha.",
  "PCP — проект":
    "PCP — raqamli aktivlarni himoya qiladigan va Internetdan xavfsiz foydalanishni ta’minlovchi loyiha.",
  "SHIFO24— инновационный проект":
    "SHIFO24 — Onlayn tibbiy xizmatlar sohasida xavfsizlikni ta'minlashga qaratilgan innovatsion loyiha.",
  "Yemak — служба доставки еды.":
    "Yemak  — oziq-ovqat yetkazib berish xizmati.",
  "Joyla— торговая площадка":
    "Joyla — Barcha turdagi mahsulotlarni tezkor onlayn sotib olish va sotish imkonini beruvchi bozor.",
  "IMAN — финтех-платформа":
    "IMAN — banklar uchun islomiy bank infratuzilmasini qo‘llab-quvvatlovchi fintech platforma.",
  "AirPilot — мобильная игра":
    "AirPilot — virtual va real hayot sharoitida havo harakatini boshqarishning turli vositalarini boshqarish va ular bilan tanishish qobiliyatiga ega bo'lgan yoshlarda aerodinamika va aerokosmik texnologiyalarga qiziqish uyg'otadigan mobil o'yin.",
  "The Rise of Jaloliddin":
    "The Rise of Jaloliddin — “Jaloliddinning yuksalishi” Turkiston xalqi tarixini ko‘rsatishga harakat qiladigan tarixiy o‘yin.",
  "The Golden Horde":
    "The Golden Horde: Turan  — Markaziy Osiyoning  reallik va tarixiy voqealariga asoslangan o'rta asrlardagi 1-shaxs  nomidan  boshqariladigan “action-RPG” shaklidagi o‘yin.",
    "Оставьте заявку и мы оповестим вас о начале принятия заявок.":"Ariza qoldiring va biz arizalarni qabul qilish boshlanganligi haqida sizni xabardor qilamiz.",
    "Отлично. Мы отправим вам сообщение, когда откроется регистрация конкурса.":"Yaxshi. Tanlovda ro‘yxatdan o‘tish ochilishi bilan sizga xabar yuboramiz.",
    "Процесс создания и формирования команды для участия в конкурсе завершен 25 августа 2024 года":"Musobaqada ishtirok etish uchun jamoani yaratish va shakllantirish jarayoni 2024-yil 25-avgust kuni yakunlandi",
    "Процесс подачи заявки для участия в конкурсе завершен 25 августа 2024 года":"Tanlovda ishtirok etish uchun ariza topshirish jarayoni 2024-yil 25-avgustda yakunlandi",

    "Процесс подачи заявки для участия в конкурсе завершен 30 августа 2024 года":"Tanlovda ishtirok etish uchun ariza topshirish jarayoni 2024-yil 30-avgustda yakunlandi",
    "Процесс создания и формирования команды для участия в Хакатоне завершен 30 августа 2024 года.":"Xakatonda ishtirok etish uchun jamoani yaratish va shakllantirish jarayoni 2024-yil 30-avgustda yakunlandi.",
    "Презентация прикреплена":"Taqdimot yuklandi",
  "Оценка поставлена команде": "Jamoa baholandi",
  "Чекпоинты Хакатона | Чекпоинт 1":"Hakaton chekpointlari | Chekpoint 1",
  "Чекпоинты Хакатона | Чекпоинт 2":"Hakaton chekpointlari | Chekpoint 2",
  "Чекпоинты Хакатона | Чекпоинт 3":"Hakaton chekpointlari | Chekpoint 3",
  "Чекпоинты Хакатона":"Hakaton chekpointlari",
  "Чекпоинт 1":"Chekpoint 1",
  "Чекпоинт 2":"Chekpoint 2",
  "Чекпоинт 3":"Chekpoint 3",
  "Описание":"Ta'snif",
  "Оценочный лист":"Baho varaqasi",
  "Найменование критерия": "Mezon nomi",
  "Оценки":"Baholar",
  "ВСЕГО":"JAMI",
  "Подтвердить":"Tasdiqlash",
  "Баллы успешно отправлены!":"Ballar muvaffaqiyatli yuborildi!",
  "Ссылка на Github добавлена":"Github havolasi qo'shildi",
  "Программа":"Dastur",
};
