const routes = [
  {
    path: 'cabinet',
    name: 'Cabinet',
    meta: { middleware: 'AuthApplicator', title: 'Мой профиль' },
    component: () =>
    import("@/views/member/Cabinet"),
  },{
    path: 'team',
    name: 'Team',
    redirect: { name: "MainTeamCard" },
    meta: { middleware: 'AuthApplicator' },
    component: () =>
    import("@/views/member/teams/Main"),
  },{
    path: 'team/main',
    name: 'TeamMain',
    redirect: { name: "MainTeamCard" },
    meta: { middleware: 'AuthApplicator', nav: 'teams' },
    component: () =>
    import("@/views/member/teams/Main"),
    children: [{
      path: 'card',
      name: 'MainTeamCard',
      meta: { middleware: 'AuthApplicator', nav: 'teams' },
      component: () =>
        import("@/views/member/teams/tabs/Card"),
    },{
      path: 'members',
      name: 'MainTeamMembers',
      meta: { middleware: 'AuthApplicator', nav: 'teams' },
      component: () =>
        import("@/views/member/teams/tabs/Members"),
    },{
      path: 'requests',
      name: 'MainTeamRequests',
      meta: { middleware: 'AuthApplicator', nav: 'teams' },
      component: () =>
        import("@/views/member/teams/tabs/Requests"),
    },{
      path: 'application',
      name: 'MainTeamApplication',
      meta: { middleware: 'AuthApplicator', nav: 'teams' },
      component: () =>
        import("@/views/member/teams/tabs/Application"),
    }]
  },{
    path: 'team/hackathon',
    name: 'TeamHackathon',
    redirect: { name: "TeamHackathonCard" },
    meta: { middleware: 'AuthApplicator', nav: 'teams' },
    component: () =>
    import("@/views/member/teams/Hackathon"),
    children: [{
      path: 'card',
      name: 'TeamHackathonCard',
      meta: { middleware: 'AuthApplicator', nav: 'teams' },
      component: () =>
        import("@/views/member/teams/tabs/Card"),
    },{
      path: 'members',
      name: 'TeamHackathonMembers',
      meta: { middleware: 'AuthApplicator', nav: 'teams' },
      component: () =>
        import("@/views/member/teams/tabs/Members"),
    },{
      path: 'requests',
      name: 'TeamHackathonRequests',
      meta: { middleware: 'AuthApplicator', nav: 'teams' },
      component: () =>
        import("@/views/member/teams/tabs/Requests"),
    },{
      path: 'application',
      name: 'TeamHackathonApplication',
      meta: { middleware: 'AuthApplicator', nav: 'teams' },
      component: () =>
        import("@/views/member/teams/tabs/Application"),
    }]
  }, {
    path: 'notifications',
    name: 'Notifications',
    meta: { middleware: 'AuthApplicator', title: 'Уведомления' },
    component: () =>
    import("@/views/member/Notifications"),
  }, {
    path: 'community',
    name: 'Community',
    meta: { middleware: 'AuthApplicator', title: 'Сообщество' },
    component: () =>
    import("@/views/member/Community"),
  }, {
    path: 'faq',
    name: 'Faq',
    meta: { middleware: 'AuthApplicator', title: 'FAQ' },
    component: () =>
    import("@/views/member/Faq"),
  }, {
    path: 'certificates',
    name: 'Certificates',
    meta: { middleware: 'AuthApplicator', title: 'Сертификаты' },
    component: () =>
    import("@/views/member/Certificate"),
  }, {
    path: 'jury-results',
    name: 'JuryFinalResults',
    meta: { middleware: 'AuthApplicator', title: 'Результаты жюри' },
    component: () =>
    import("@/views/member/JuryFinalResults"),
  }, {
    path: 'demo-day-1-stage-results',
    name: 'DemoDay1StageResults',
    meta: { middleware: 'AuthApplicator', title: 'Demo Day - 1 stage' },
    component: () =>
    import("@/views/member/DemoDay1StageResults"),
  }, {
    path: 'demo-day-final-results',
    name: 'DemoDayFinalResults',
    meta: { middleware: 'AuthApplicator', title: 'Demo Day Final' },
    component: () =>
    import("@/views/member/DemoDay1StageResults"),
  }, {
    path: 'hackathon-checkpints/:step',
    name: 'HackathonCheckpints',
    meta: { middleware: 'AuthApplicator', title: 'Чекпоинты Хакатона' },
    component: () =>
    import("@/views/member/HackathonCheckpints"),
  }
]

export default routes;
